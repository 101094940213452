import React, { useState } from 'react';
import {
  Container,
  Typography,
  Card,
  CardContent,
  Button,
  Grid,
  Snackbar,
  Alert,
  Box,
  Divider,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import PermissionWrapper from '../components/PermissionWrapper';
import axios from 'axios';
import config from '../config';
import CategoryIcon from '@mui/icons-material/Category';
import InventoryIcon from '@mui/icons-material/Inventory';
import StorageIcon from '@mui/icons-material/Storage';
import ImageIcon from '@mui/icons-material/Image';
import BusinessIcon from '@mui/icons-material/Business';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import StoreIcon from '@mui/icons-material/Store';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import InfoIcon from '@mui/icons-material/Info';

interface JobTriggerResponse {
  message: string;
}

const JobTriggers: React.FC = () => {
  const [loading, setLoading] = useState<string | null>(null);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: 'success' | 'error';
  }>({
    open: false,
    message: '',
    severity: 'success',
  });

  const jobs = [
    {
      endpoint: '/jobs/categories',
      title: 'Uppdatera Kategorier',
      description: 'Uppdaterar alla kategorier i systemet',
      icon: <CategoryIcon sx={{ fontSize: 40 }} />,
      info: 'Hämtar och uppdaterar alla produktkategorier från källsystemet',
    },
    {
      endpoint: '/jobs/products',
      title: 'Uppdatera Produkter',
      description: 'Uppdaterar alla produkter i systemet',
      icon: <InventoryIcon sx={{ fontSize: 40 }} />,
      info: 'Synkroniserar all produktinformation från källsystemet',
    },
    {
      endpoint: '/jobs/stock-balances',
      title: 'Uppdatera Lagersaldon',
      description: 'Uppdaterar alla lagersaldon i systemet',
      icon: <StorageIcon sx={{ fontSize: 40 }} />,
      info: 'Hämtar aktuella lagersaldon för alla produkter',
    },
    {
      endpoint: '/jobs/media',
      title: 'Uppdatera Media',
      description: 'Uppdaterar all produktmedia i systemet',
      icon: <ImageIcon sx={{ fontSize: 40 }} />,
      info: 'Synkroniserar alla produktbilder och media från källsystemet',
    },
    {
      endpoint: '/jobs/suppliers',
      title: 'Uppdatera Leverantörer',
      description: 'Uppdaterar alla leverantörer i systemet',
      icon: <BusinessIcon sx={{ fontSize: 40 }} />,
      info: 'Hämtar och uppdaterar leverantörsinformation',
    },
    {
      endpoint: '/jobs/price-validation',
      title: 'Kontrollera Priser',
      description: 'Kontrollerar och korrigerar priser i systemet',
      icon: <PriceCheckIcon sx={{ fontSize: 40 }} />,
      info: 'Validerar och korrigerar eventuella prisavvikelser',
    },
    {
      endpoint: '/jobs/sales-stats',
      title: 'Uppdatera Försäljningsstatistik',
      description: 'Uppdaterar försäljningsstatistik i systemet',
      icon: <ShowChartIcon sx={{ fontSize: 40 }} />,
      info: 'Beräknar och uppdaterar all försäljningsstatistik',
    },
    {
      endpoint: '/jobs/retail-sales',
      title: 'Uppdatera Butiksförsäljning',
      description: 'Uppdaterar butiksförsäljning i systemet',
      icon: <StoreIcon sx={{ fontSize: 40 }} />,
      info: 'Hämtar och uppdaterar försäljningsdata från butiker',
    },
    {
      endpoint: '/jobs/run-all',
      title: 'Kör Alla Jobb',
      description: 'Kör alla jobb ovan i sekvens',
      icon: <PlaylistPlayIcon sx={{ fontSize: 40 }} />,
      info: 'Kör alla uppdateringsjobb i optimal ordning',
    },
  ];

  const handleTriggerJob = async (endpoint: string, title: string) => {
    setLoading(endpoint);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post<JobTriggerResponse>(
        `${config.API_URL}${endpoint}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      );

      setSnackbar({
        open: true,
        message: response.data.message,
        severity: 'success',
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message:
          error instanceof Error
            ? error.message
            : 'Ett fel uppstod vid körning av jobbet',
        severity: 'error',
      });
    } finally {
      setLoading(null);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <PermissionWrapper requiredPermissions={['manage_companies']}>
      <Container>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h4" gutterBottom>
            Systemjobb
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Här kan du manuellt trigga olika systemjobb som normalt körs
            automatiskt enligt schema.
          </Typography>
        </Box>

        <Grid container spacing={3}>
          {jobs.map((job) => (
            <Grid item xs={12} sm={6} md={4} key={job.endpoint}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'relative',
                  transition: 'transform 0.2s, box-shadow 0.2s',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: (theme) => theme.shadows[4],
                  },
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: '4px',
                    background: 'linear-gradient(90deg, #BB86FC, #03DAC6)',
                  },
                }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      mb: 2,
                      color: 'primary.main',
                    }}
                  >
                    {job.icon}
                    <Box sx={{ ml: 2, flexGrow: 1 }}>
                      <Typography variant="h6" gutterBottom>
                        {job.title}
                      </Typography>
                    </Box>
                    <Tooltip title={job.info} placement="top">
                      <InfoIcon color="action" sx={{ ml: 1 }} />
                    </Tooltip>
                  </Box>
                  <Divider sx={{ my: 1 }} />
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2, minHeight: '40px' }}
                  >
                    {job.description}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => handleTriggerJob(job.endpoint, job.title)}
                    disabled={loading !== null}
                    startIcon={
                      loading === job.endpoint ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : null
                    }
                  >
                    {loading === job.endpoint ? 'Kör...' : 'Kör jobb'}
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbar.severity}
            sx={{ width: '100%' }}
            variant="filled"
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </PermissionWrapper>
  );
};

export default JobTriggers;
