import React, { useContext, useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Box,
  Badge,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import InventoryIcon from '@mui/icons-material/Inventory';
import DescriptionIcon from '@mui/icons-material/Description';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import GroupIcon from '@mui/icons-material/Group';
import BusinessIcon from '@mui/icons-material/Business';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import NotificationsIcon from '@mui/icons-material/Notifications';
import BuildIcon from '@mui/icons-material/Build';
import { styled } from '@mui/material/styles';
import StockNotificationsDialog from './StockNotificationsDialog';
import config from '../config';
import { StockNotification } from '../types/notifications';

const GradientIcon = styled('span')(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  '& .MuiSvgIcon-root': {
    fontSize: 24,
    background: 'linear-gradient(45deg, #BB86FC, #03DAC6)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    display: 'block',
  },
}));

const CustomAppBar: React.FC = () => {
  const navigate = useNavigate();
  const { setToken, setPermissions, permissions } = useContext(AuthContext);
  const [anchorElProducts, setAnchorElProducts] = useState<null | HTMLElement>(
    null,
  );
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [notifications, setNotifications] = useState<StockNotification[]>([]);

  // Kontrollera om skärmen är mobil/surfplatta
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    // Hämta notifikationer när komponenten monteras
    fetchNotifications();

    // Sätt upp en intervall för att uppdatera notifikationer var 5:e minut
    const interval = setInterval(fetchNotifications, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  const fetchNotifications = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(
        `${config.API_URL}/notifications/stock-changes`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      );
      if (response.ok) {
        const data = await response.json();
        setNotifications(data);
      } else {
        console.error(
          'Fel vid hämtning av notifikationer:',
          await response.text(),
        );
      }
    } catch (error) {
      console.error('Fel vid hämtning av notifikationer:', error);
    }
  };

  const handleMarkAsRead = async (notificationId: number) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(
        `${config.API_URL}/notifications/stock-changes/${notificationId}/mark-read`,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      );
      if (response.ok) {
        setNotifications(
          notifications.filter((n) => n.notificationId !== notificationId),
        );
      } else {
        console.error(
          'Fel vid markering av notifikation som läst:',
          await response.text(),
        );
      }
    } catch (error) {
      console.error('Fel vid markering av notifikation som läst:', error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('permissions');
    setToken(null);
    setPermissions([]);
    navigate('/');
  };

  // Öppna/stäng meny för Produkter
  const handleProductsMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElProducts(event.currentTarget);
  };

  const handleProductsMenuClose = () => {
    setAnchorElProducts(null);
  };

  // Navigera och stänga menyn
  const handleNavigate = (path: string) => {
    navigate(path);
    handleProductsMenuClose();
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography
          variant="h6"
          sx={{
            background: 'linear-gradient(45deg, #BB86FC, #03DAC6)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            flexGrow: 1,
            cursor: 'pointer',
          }}
          onClick={() => handleNavigate('/dashboard')}
        >
          MiRAKEL
        </Typography>

        {/* Om skärmen är desktop */}
        {!isMobile && (
          <Box>
            {/* Notifikationsikon - endast synlig för användare med manage_product_descriptions */}
            {permissions.includes('manage_product_descriptions') && (
              <IconButton
                color="inherit"
                onClick={() => setNotificationsOpen(true)}
                sx={{ mr: 2 }}
              >
                <Badge badgeContent={notifications.length} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            )}

            {/* Dropdown-meny för huvudkategori Produkter */}
            <Button color="inherit" onClick={handleProductsMenuOpen}>
              <GradientIcon>
                <InventoryIcon sx={{ mr: 1 }} />
              </GradientIcon>
              Produkter
            </Button>
            <Menu
              anchorEl={anchorElProducts}
              open={Boolean(anchorElProducts)}
              onClose={handleProductsMenuClose}
            >
              {permissions.includes('manage_product_descriptions') && (
                <MenuItem onClick={() => handleNavigate('/product-management')}>
                  <GradientIcon>
                    <DescriptionIcon sx={{ mr: 1 }} />
                  </GradientIcon>
                  Hantera Produkter
                </MenuItem>
              )}
              {permissions.includes('manage_prices') && (
                <MenuItem onClick={() => handleNavigate('/price-change')}>
                  <GradientIcon>
                    <PriceChangeIcon sx={{ mr: 1 }} />
                  </GradientIcon>
                  Prishantering
                </MenuItem>
              )}
              {permissions.includes('manage_product_descriptions') && (
                <MenuItem onClick={() => handleNavigate('/product-import')}>
                  <GradientIcon>
                    <CloudUploadIcon sx={{ mr: 1 }} />
                  </GradientIcon>
                  Produktimport
                </MenuItem>
              )}
              <MenuItem onClick={() => handleNavigate('/sales')}>
                <GradientIcon>
                  <ShowChartIcon sx={{ mr: 1 }} />
                </GradientIcon>
                Försäljning
              </MenuItem>
            </Menu>
            {permissions.includes('manage_users') && (
              <Button
                color="inherit"
                onClick={() => handleNavigate('/user-management')}
              >
                <GradientIcon>
                  <GroupIcon sx={{ mr: 1 }} />
                </GradientIcon>
                Användarhantering
              </Button>
            )}
            {permissions.includes('manage_companies') && (
              <Button color="inherit" onClick={() => handleNavigate('/jobs')}>
                <GradientIcon>
                  <BuildIcon sx={{ mr: 1 }} />
                </GradientIcon>
                Systemjobb
              </Button>
            )}
            {/*
            {permissions.includes('manage_companies') && (
              <Button
                color="inherit"
                onClick={() => handleNavigate('/company-management')}
              >
                <GradientIcon>
                  <BusinessIcon sx={{ mr: 1 }} />
                </GradientIcon>
                Företagshantering
              </Button>
            )}
            {permissions.includes('access_chatgpt') && (
              <Button
                color="inherit"
                onClick={() => handleNavigate('/chatgpt-access')}
              >
                <GradientIcon>
                  <SmartToyIcon sx={{ mr: 1 }} />
                </GradientIcon>
                ChatGPT Tillgång
              </Button>
            )}
            */}
          </Box>
        )}

        {/* Om skärmen är mobil/surfplatta, visa hamburgermeny */}
        {isMobile && (
          <>
            {/* Notifikationsikon för mobil */}
            {permissions.includes('manage_product_descriptions') && (
              <IconButton
                color="inherit"
                onClick={() => setNotificationsOpen(true)}
                sx={{ mr: 2 }}
              >
                <Badge badgeContent={notifications.length} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            )}

            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleProductsMenuOpen}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorElProducts}
              open={Boolean(anchorElProducts)}
              onClose={handleProductsMenuClose}
            >
              {/* Menyalternativ för mobila enheter */}
              {permissions.includes('manage_product_descriptions') && (
                <MenuItem onClick={() => handleNavigate('/product-management')}>
                  <GradientIcon>
                    <DescriptionIcon sx={{ mr: 1 }} />
                  </GradientIcon>
                  Hantera Produkter
                </MenuItem>
              )}
              {permissions.includes('manage_prices') && (
                <MenuItem onClick={() => handleNavigate('/price-change')}>
                  <GradientIcon>
                    <PriceChangeIcon sx={{ mr: 1 }} />
                  </GradientIcon>
                  Prisändringar
                </MenuItem>
              )}
              {permissions.includes('manage_users') && (
                <MenuItem onClick={() => handleNavigate('/user-management')}>
                  <GradientIcon>
                    <GroupIcon sx={{ mr: 1 }} />
                  </GradientIcon>
                  Användarhantering
                </MenuItem>
              )}
              <MenuItem onClick={() => handleNavigate('/sales')}>
                <GradientIcon>
                  <ShowChartIcon sx={{ mr: 1 }} />
                </GradientIcon>
                Försäljning
              </MenuItem>
            </Menu>
          </>
        )}

        <Button color="inherit" onClick={handleLogout}>
          Logga ut
        </Button>

        {/* Notifikationsdialog */}
        <StockNotificationsDialog
          open={notificationsOpen}
          onClose={() => setNotificationsOpen(false)}
          notifications={notifications}
          onMarkAsRead={handleMarkAsRead}
        />
      </Toolbar>
    </AppBar>
  );
};

export default CustomAppBar;
