// SelectComponent.tsx
import React, { useLayoutEffect, useRef, memo, useState } from 'react';
import Select, { GroupBase, SelectInstance } from 'react-select';
import { CellProps, CellComponent } from 'react-datasheet-grid';

interface Choice {
  label: string;
  value: string;
}

interface SelectOptions {
  choices: Choice[];
  disabled?: boolean;
}

const SelectComponent: CellComponent<string | null, SelectOptions> = ({
  active,
  rowData,
  setRowData,
  focus,
  stopEditing,
  columnData,
}: CellProps<string | null, SelectOptions>) => {
  const ref = useRef<SelectInstance<Choice, false, GroupBase<Choice>>>(null);
  const [inputValue, setInputValue] = useState('');

  useLayoutEffect(() => {
    if (focus) {
      ref.current?.focus();
    } else {
      ref.current?.blur();
    }
  }, [focus]);

  const filterAndSortOptions = (option: Choice, input: string) => {
    if (!input) return true;

    const optionLabel = option.label.toLowerCase();
    const searchValue = input.toLowerCase();

    return optionLabel.includes(searchValue);
  };

  return (
    <Select
      ref={ref}
      styles={{
        container: (provided) => ({
          ...provided,
          flex: 1,
          alignSelf: 'stretch',
          pointerEvents: focus ? undefined : 'none',
        }),
        control: (provided) => ({
          ...provided,
          height: '100%',
          border: 'none',
          boxShadow: 'none',
          background: 'none',
        }),
        indicatorSeparator: () => ({
          display: 'none',
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          opacity: active ? 1 : 0,
          position: 'absolute',
          right: 0,
          top: 0,
          height: '100%',
          backgroundColor: 'white',
          padding: '0 8px',
        }),
        placeholder: (provided) => ({
          ...provided,
          opacity: active ? 1 : 0,
        }),
        option: (provided, state) => ({
          ...provided,
          color: 'black',
          backgroundColor: state.isSelected ? '#BB86FC' : 'white',
          '&:hover': {
            backgroundColor: '#BB86FC40',
          },
        }),
        singleValue: (provided) => ({
          ...provided,
          color: 'inherit',
        }),
        menu: (provided) => ({
          ...provided,
          backgroundColor: 'white',
        }),
      }}
      isDisabled={columnData.disabled}
      value={columnData.choices.find(({ value }) => value === rowData) ?? null}
      menuPortalTarget={document.body}
      menuIsOpen={focus}
      onChange={(choice) => {
        if (choice === null) return;
        setRowData(choice.value);
        setTimeout(stopEditing, 0);
      }}
      onInputChange={(newValue, { action }) => {
        if (action === 'input-change') {
          setInputValue(newValue);
        }
      }}
      onMenuClose={() => stopEditing({ nextRow: false })}
      filterOption={filterAndSortOptions}
      options={columnData.choices.sort((a, b) => {
        if (!inputValue) return 0;

        const searchValue = inputValue.toLowerCase();
        const aLabel = a.label.toLowerCase();
        const bLabel = b.label.toLowerCase();

        const aExact = aLabel === searchValue;
        const bExact = bLabel === searchValue;

        if (aExact && !bExact) return -1;
        if (!aExact && bExact) return 1;

        const aStartsWith = aLabel.startsWith(searchValue);
        const bStartsWith = bLabel.startsWith(searchValue);

        if (aStartsWith && !bStartsWith) return -1;
        if (!aStartsWith && bStartsWith) return 1;

        return a.label.localeCompare(b.label);
      })}
    />
  );
};

// Memoisera komponenten och typasserta den som CellComponent
export default memo(SelectComponent) as CellComponent<
  string | null,
  SelectOptions
>;
