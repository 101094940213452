import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  CircularProgress,
  Grow,
} from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import GroupIcon from '@mui/icons-material/Group';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import InventoryIcon from '@mui/icons-material/Inventory';
import UpdateIcon from '@mui/icons-material/Update';
import CategoryIcon from '@mui/icons-material/Category';
import axios from 'axios';
import config from '../config';

interface DashboardStats {
  products: {
    total: number;
    brands: number;
    categories: number;
    needingDescription: number;
  };
  prices: {
    productsWithPrice: number;
    averagePrice: number;
    onSale: number;
  };
}

interface Import {
  type: 'import';
  name: string;
  status: string;
  timestamp: string;
  rowCount: number;
}

interface ActivitiesResponse {
  imports: Import[];
}

interface ActivityItem {
  type: 'price' | 'product' | 'description';
  message: string;
  timestamp: string;
}

const Dashboard: React.FC = () => {
  const [stats, setStats] = useState<DashboardStats>({
    products: {
      total: 0,
      brands: 0,
      categories: 0,
      needingDescription: 0,
    },
    prices: {
      productsWithPrice: 0,
      averagePrice: 0,
      onSale: 0,
    },
  });

  const [recentActivities, setRecentActivities] = useState<ActivityItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const handleProductManagementClick = () => {
    navigate('/product-management');
  };

  const handlePriceManagementClick = () => {
    navigate('/price-change');
  };

  useEffect(() => {
    const fetchDashboardData = async () => {
      setIsLoading(true);
      try {
        const [statsResponse, activitiesResponse] = await Promise.all([
          axios.get<DashboardStats>(`${config.API_URL}/dashboard/stats`),
          axios.get<ActivitiesResponse>(
            `${config.API_URL}/dashboard/activities`,
          ),
        ]);

        setStats(statsResponse.data);

        const allActivities = activitiesResponse.data.imports.map((imp) => ({
          type: 'product' as const,
          message: `Produktimport: ${imp.name} (${imp.rowCount} rader)`,
          timestamp: imp.timestamp,
        }));

        setRecentActivities(allActivities);
      } catch (error) {
        console.error('Fel vid hämtning av dashboard-data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDashboardData();
    const interval = setInterval(fetchDashboardData, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  // Lägg till animation för statistik
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
  }, [stats]);

  if (isLoading) {
    return (
      <Container>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      <Box sx={{ mt: 4, mb: 6 }}>
        <Typography
          variant="h1"
          gutterBottom
          sx={{
            fontSize: '2.5rem',
            fontWeight: 600,
            textAlign: 'center',
            mb: 4,
            background: 'linear-gradient(45deg, #BB86FC, #03DAC6)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            animation: animate ? 'fadeIn 0.5s ease-in' : 'none',
          }}
        >
          Välkommen till MiRAKEL
        </Typography>
      </Box>

      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              height: '100%',
              background: 'linear-gradient(135deg, #BB86FC20, #BB86FC10)',
              transition: 'all 0.3s ease-in-out',
              animation: animate ? 'slideIn 0.5s ease-in' : 'none',
              position: 'relative',
              overflow: 'hidden',
              '&:hover': {
                transform: 'scale(1.05) rotate(2deg)',
                background: 'linear-gradient(135deg, #BB86FC30, #BB86FC20)',
                '& .icon': {
                  transform: 'rotate(10deg) scale(1.2)',
                },
                '& .number': {
                  transform: 'scale(1.1)',
                  color: '#BB86FC',
                },
              },
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                height: '4px',
                background: 'linear-gradient(90deg, #BB86FC, #03DAC6)',
              },
            }}
          >
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <InventoryIcon
                  className="icon"
                  sx={{
                    fontSize: 40,
                    color: '#BB86FC',
                    mr: 2,
                    transition: 'transform 0.3s ease-in-out',
                  }}
                />
                <Typography
                  className="number"
                  variant="h4"
                  sx={{
                    transition: 'all 0.3s ease-in-out',
                  }}
                >
                  {stats.products.total}
                </Typography>
              </Box>
              <Typography variant="body2" color="text.secondary">
                Totalt antal produkter
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              height: '100%',
              background: 'linear-gradient(135deg, #9C27B020, #9C27B010)',
              transition: 'all 0.3s ease-in-out',
              animation: animate ? 'slideIn 0.5s ease-in' : 'none',
              '&:hover': {
                transform: 'scale(1.05) rotate(2deg)',
                background: 'linear-gradient(135deg, #9C27B030, #9C27B020)',
                '& .icon': {
                  transform: 'rotate(10deg) scale(1.2)',
                },
                '& .number': {
                  transform: 'scale(1.1)',
                  color: '#9C27B0',
                },
              },
            }}
          >
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <AssignmentIcon
                  className="icon"
                  sx={{
                    fontSize: 40,
                    color: '#9C27B0',
                    mr: 2,
                    transition: 'transform 0.3s ease-in-out',
                  }}
                />
                <Typography
                  className="number"
                  variant="h4"
                  sx={{
                    transition: 'all 0.3s ease-in-out',
                  }}
                >
                  {Math.round(
                    (stats.products.needingDescription / stats.products.total) *
                      100,
                  )}
                  %
                </Typography>
              </Box>
              <Typography variant="body2" color="text.secondary">
                Behöver beskrivning
              </Typography>
              <Box sx={{ mt: 2 }}>
                <Typography variant="caption" color="text.secondary">
                  {stats.products.needingDescription} av {stats.products.total}{' '}
                  produkter
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={
                    100 -
                    (stats.products.needingDescription / stats.products.total) *
                      100
                  }
                  sx={{
                    mt: 1,
                    height: 8,
                    borderRadius: 4,
                    backgroundColor: 'rgba(156, 39, 176, 0.1)',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#9C27B0',
                    },
                  }}
                />
                <Typography variant="caption" color="text.secondary">
                  {Math.round(
                    100 -
                      (stats.products.needingDescription /
                        stats.products.total) *
                        100,
                  )}
                  % är kompletta
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              height: '100%',
              background: 'linear-gradient(135deg, #CF667920, #CF667910)',
              transition: 'all 0.3s ease-in-out',
              animation: animate ? 'slideIn 0.5s ease-in' : 'none',
              '&:hover': {
                transform: 'scale(1.05) rotate(2deg)',
                background: 'linear-gradient(135deg, #CF667930, #CF667920)',
                '& .icon': {
                  transform: 'rotate(15deg) scale(1.2)',
                },
                '& .number': {
                  transform: 'scale(1.1)',
                  color: '#CF6679',
                },
              },
            }}
          >
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <CategoryIcon
                  className="icon"
                  sx={{
                    fontSize: 40,
                    color: '#CF6679',
                    mr: 2,
                    transition: 'transform 0.3s ease-in-out',
                  }}
                />
                <Typography
                  className="number"
                  variant="h4"
                  sx={{
                    transition: 'all 0.3s ease-in-out',
                  }}
                >
                  {stats.products.categories}
                </Typography>
              </Box>
              <Typography variant="body2" color="text.secondary">
                Antal kategorier
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              height: '100%',
              background: 'linear-gradient(135deg, #4CAF5020, #4CAF5010)',
              transition: 'all 0.3s ease-in-out',
              animation: animate ? 'slideIn 0.5s ease-in' : 'none',
              '&:hover': {
                transform: 'scale(1.05) rotate(2deg)',
                background: 'linear-gradient(135deg, #4CAF5030, #4CAF5020)',
                '& .icon': {
                  transform: 'rotate(-15deg) scale(1.2)',
                },
                '& .number': {
                  transform: 'scale(1.1)',
                  color: '#4CAF50',
                },
              },
            }}
          >
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <GroupIcon
                  className="icon"
                  sx={{
                    fontSize: 40,
                    color: '#4CAF50',
                    mr: 2,
                    transition: 'transform 0.3s ease-in-out',
                  }}
                />
                <Typography
                  className="number"
                  variant="h4"
                  sx={{
                    transition: 'all 0.3s ease-in-out',
                  }}
                >
                  {stats.products.brands}
                </Typography>
              </Box>
              <Typography variant="body2" color="text.secondary">
                Antal varumärken
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              height: '100%',
              background: 'linear-gradient(135deg, #FF408120, #FF408110)',
              transition: 'all 0.3s ease-in-out',
              animation: animate ? 'slideIn 0.5s ease-in' : 'none',
              '&:hover': {
                transform: 'scale(1.05) rotate(2deg)',
                background: 'linear-gradient(135deg, #FF408130, #FF408120)',
                '& .icon': {
                  transform: 'rotate(10deg) scale(1.2)',
                },
                '& .number': {
                  transform: 'scale(1.1)',
                  color: '#FF4081',
                },
              },
            }}
          >
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <MonetizationOnIcon
                  className="icon"
                  sx={{
                    fontSize: 40,
                    color: '#FF4081',
                    mr: 2,
                    transition: 'transform 0.3s ease-in-out',
                  }}
                />
                <Typography
                  className="number"
                  variant="h4"
                  sx={{
                    transition: 'all 0.3s ease-in-out',
                  }}
                >
                  {stats.prices.onSale}
                </Typography>
              </Box>
              <Typography variant="body2" color="text.secondary">
                Produkter har kampanjpris
              </Typography>
              <Box sx={{ mt: 2 }}>
                <Typography variant="caption" color="text.secondary">
                  Genomsnittspris:{' '}
                  {stats.prices.averagePrice.toLocaleString('sv-SE', {
                    style: 'currency',
                    currency: 'SEK',
                  })}
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={
                    (stats.prices.productsWithPrice / stats.products.total) *
                    100
                  }
                  sx={{
                    mt: 1,
                    height: 8,
                    borderRadius: 4,
                    backgroundColor: 'rgba(255, 64, 129, 0.1)',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#FF4081',
                    },
                  }}
                />
                <Typography variant="caption" color="text.secondary">
                  {Math.round(
                    (stats.prices.productsWithPrice / stats.products.total) *
                      100,
                  )}
                  % har priser
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  height: '100%',
                  cursor: 'pointer',
                  position: 'relative',
                  overflow: 'hidden',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    transition: 'transform 0.2s',
                    boxShadow: (theme) => theme.shadows[6],
                  },
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: '4px',
                    background: 'linear-gradient(90deg, #BB86FC, #03DAC6)',
                  },
                }}
                onClick={handleProductManagementClick}
              >
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <AssignmentIcon
                      sx={{ fontSize: 40, color: 'primary.main', mr: 2 }}
                    />
                    <Typography variant="h5">Produkthantering</Typography>
                  </Box>
                  <Typography variant="body1" color="text.secondary" paragraph>
                    Hantera produktbeskrivningar och bilder för alla artiklar i
                    systemet.
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="body2" color="primary">
                      • Ladda upp produktbilder
                    </Typography>
                    <Typography variant="body2" color="primary">
                      • Redigera beskrivningar
                    </Typography>
                    <Typography variant="body2" color="primary">
                      • AI-assisterad innehållsgenerering
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  height: '100%',
                  cursor: 'pointer',
                  position: 'relative',
                  overflow: 'hidden',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    transition: 'transform 0.2s',
                    boxShadow: (theme) => theme.shadows[6],
                  },
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: '4px',
                    background: 'linear-gradient(90deg, #BB86FC, #03DAC6)',
                  },
                }}
                onClick={handlePriceManagementClick}
              >
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <MonetizationOnIcon
                      sx={{ fontSize: 40, color: 'primary.main', mr: 2 }}
                    />
                    <Typography variant="h5">Prishantering</Typography>
                  </Box>
                  <Typography variant="body1" color="text.secondary" paragraph>
                    Uppdatera och hantera priser för alla produkter.
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="body2" color="primary">
                      • Massuppdatering av priser
                    </Typography>
                    <Typography variant="body2" color="primary">
                      • Kampanjprissättning
                    </Typography>
                    <Typography variant="body2" color="primary">
                      • Prishistorik och analys
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card
            sx={{ height: '100%', position: 'relative', overflow: 'hidden' }}
          >
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <UpdateIcon
                  sx={{
                    fontSize: 40,
                    color: 'primary.main',
                    mr: 2,
                    animation: 'pulse 2s infinite',
                  }}
                />
                <Typography variant="h5">Senaste aktiviteter</Typography>
              </Box>
              <List>
                {recentActivities.map((activity, index) => (
                  <Grow key={index} in={true} timeout={(index + 1) * 200}>
                    <ListItem
                      sx={{
                        transition: 'all 0.3s ease',
                        '&:hover': {
                          backgroundColor: 'rgba(187, 134, 252, 0.1)',
                          transform: 'translateX(10px)',
                        },
                      }}
                    >
                      <ListItemIcon>
                        {activity.type === 'price' && (
                          <MonetizationOnIcon color="primary" />
                        )}
                        {activity.type === 'product' && (
                          <InventoryIcon color="primary" />
                        )}
                        {activity.type === 'description' && (
                          <SmartToyIcon color="primary" />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={activity.message}
                        secondary={activity.timestamp}
                      />
                    </ListItem>
                  </Grow>
                ))}
              </List>
            </CardContent>
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                height: '4px',
                background: 'linear-gradient(90deg, #BB86FC, #03DAC6)',
              }}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
