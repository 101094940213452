// ProductList.tsx
import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
  Container,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Autocomplete,
  Chip,
  IconButton,
  CircularProgress,
  Box,
  ImageList,
  Grid,
  Alert,
  Snackbar,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  TableContainer,
} from '@mui/material';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import axios from 'axios';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import SmartImage from '../components/SmartImage';
import UploadMediaModal from '../components/UploadMediaModal';
import config from '../config';
import FilterListIcon from '@mui/icons-material/FilterList';
import DescriptionIcon from '@mui/icons-material/Description';
import CategoryIcon from '@mui/icons-material/Category';
import ImageIcon from '@mui/icons-material/Image';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';
import InventoryIcon from '@mui/icons-material/Inventory';
import WarningIcon from '@mui/icons-material/Warning';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

interface Product {
  productId: number;
  productNumber: string;
  productName: string;
  hasCategories: number;
  hasValidDescription: number;
  totalColors: number;
  colorsWithImages: number;
  longDescription?: string;
  brandName?: string;
  stockByColor?: {
    [key: string]: StockInfo;
  };
  keywords?: string[];
  mediaUrl?: string;
}

interface Category {
  categoryId: number;
  categoryName: string;
  parentCategoryId: number | null;
  children?: Category[];
}

interface CategoryOption {
  categoryId: number;
  categoryName: string;
  fullPath: string;
  parentCategoryId: number | null;
  depth: number;
}

interface SKU {
  skuId: number;
  priceLookupUnitNumber: string;
  attribute1Code: string;
  attribute1Number: string;
  missingPrice?: boolean;
}

interface UploadData {
  articleNumber: string;
  colorCode: string;
  attribute1Number: string;
  images: File[];
  transparentIndices: number[];
}

interface StockInfo {
  attribute1Code: string;
  attribute1Number: string;
  stockBalance: number;
}

interface SKUPrice {
  skuId: number;
  priceLookupUnitNumber: string;
  attribute1Code: string;
  unitPrice: number;
  unitPriceOriginal: number;
  taxRate: number;
}

interface Badge {
  categoryId: number;
  categoryName: string;
  parentCategoryId: number | null;
  children?: any[];
}

const ExpandMore = styled((props: any) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ProductList: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(0); // zero-based index
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [longDescriptionFilter, setLongDescriptionFilter] = useState(false);
  const [noCategoryFilter, setNoCategoryFilter] = useState(false);
  const [noImageFilter, setNoImageFilter] = useState(false);
  const [partialImageFilter, setPartialImageFilter] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [productDescription, setProductDescription] = useState('');
  const [categoryOptions, setCategoryOptions] = useState<CategoryOption[]>([]);
  const [categoryMap, setCategoryMap] = useState<{
    [key: number]: CategoryOption;
  }>({});
  const [parentMap, setParentMap] = useState<{ [key: number]: number | null }>(
    {},
  );
  const [selectedCategories, setSelectedCategories] = useState<
    CategoryOption[]
  >([]);
  const [productCategoryIds, setProductCategoryIds] = useState<number[]>([]);
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty(),
  );
  const [isGenerating, setIsGenerating] = useState(false);
  const [productMedia, setProductMedia] = useState<{
    [attribute1Number: string]: string[];
  }>({});
  const [skus, setSkus] = useState<SKU[]>([]);

  // Lägg till state för tillgängliga färger
  const [availableColors, setAvailableColors] = useState<string[]>([]);

  // State för Upload Media Modal
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [selectedSku, setSelectedSku] = useState<SKU | null>(null);
  const [uploadedImages, setUploadedImages] = useState<string[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  // Lägg till ny state-variabel
  const [noBrandInDescFilter, setNoBrandInDescFilter] = useState(false);

  // Lägg till state för expand/collapse
  const [expanded, setExpanded] = useState(false);

  // Lägg till nya state-variabler
  const [noStockFilter, setNoStockFilter] = useState(false);
  const [partialStockFilter, setPartialStockFilter] = useState(false);

  // Lägg till ny state-variabel
  const [isSaving, setIsSaving] = useState(false);

  // Lägg till ny state-variabel
  const [noDescriptionFilter, setNoDescriptionFilter] = useState(false);

  const [activeTab, setActiveTab] = useState(0);

  // Lägg till nya states för prishantering
  const [priceInputs, setPriceInputs] = useState<{
    [skuId: number]: { unitPrice: string; unitPriceOriginal: string };
  }>({});
  const [discountInput, setDiscountInput] = useState<{
    [skuId: number]: string;
  }>({});
  const [skuPrices, setSkuPrices] = useState<SKUPrice[]>([]);

  // Lägg till dessa nya states
  const [badges, setBadges] = useState<Badge[]>([]);
  const [selectedBadges, setSelectedBadges] = useState<Badge[]>([]);
  const [productBadgeIds, setProductBadgeIds] = useState<number[]>([]);

  // Lägg till ny state-variabel
  const [hasStockFilter, setHasStockFilter] = useState(false);

  // Lägg till funktion för att beräkna rabattprocent
  const calculateDiscountPercentage = (
    originalPrice: number,
    currentPrice: number,
  ): string => {
    if (originalPrice <= 0 || currentPrice >= originalPrice) return '';
    const discount = ((originalPrice - currentPrice) / originalPrice) * 100;
    return discount.toFixed(0);
  };

  // Uppdatera useEffect för att sätta initiala priser och rabatter
  useEffect(() => {
    if (skuPrices.length > 0) {
      // Sätt priser
      const initialPriceInputs: {
        [skuId: number]: { unitPrice: string; unitPriceOriginal: string };
      } = {};
      const initialDiscountInputs: { [skuId: number]: string } = {};

      skuPrices.forEach((sku) => {
        initialPriceInputs[sku.skuId] = {
          unitPrice: sku.unitPrice.toString().replace('.', ','),
          unitPriceOriginal: sku.unitPriceOriginal.toString().replace('.', ','),
        };

        // Beräkna och sätt initial rabattprocent
        const discountPercentage = calculateDiscountPercentage(
          sku.unitPriceOriginal,
          sku.unitPrice,
        );
        if (discountPercentage) {
          initialDiscountInputs[sku.skuId] = discountPercentage;
        }
      });

      setPriceInputs(initialPriceInputs);
      setDiscountInput(initialDiscountInputs);
    }
  }, [skuPrices]);

  // Lägg till refs för varje sektion
  const descriptionRef = useRef<HTMLDivElement>(null);
  const categoriesRef = useRef<HTMLDivElement>(null);
  const mediaRef = useRef<HTMLDivElement>(null);
  const pricesRef = useRef<HTMLDivElement>(null);

  // Uppdatera handleTabChange för att scrolla till rätt sektion
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    const refs = [descriptionRef, categoriesRef, mediaRef, pricesRef];
    refs[newValue]?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`product-tabpanel-${index}`}
        aria-labelledby={`product-tab-${index}`}
        {...other}
        style={{ height: '100%', overflowY: 'auto' }}
      >
        {value === index && <Box sx={{ p: 3, height: '100%' }}>{children}</Box>}
      </div>
    );
  }

  // Hanterare för expand/collapse
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line
  }, [
    page,
    rowsPerPage,
    search,
    longDescriptionFilter,
    noCategoryFilter,
    noImageFilter,
    partialImageFilter,
    noBrandInDescFilter,
    noStockFilter,
    partialStockFilter,
    noDescriptionFilter,
    hasStockFilter,
  ]);

  useEffect(() => {
    fetchAllCategories();
    fetchAllBadges();
  }, []);

  const fetchProducts = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${config.API_URL}/products`, {
        params: {
          search,
          page: page + 1, // backend pages start from 1
          pageSize: rowsPerPage,
          longDescriptionFilter,
          noCategoryFilter,
          noImageFilter,
          partialImageFilter,
          noBrandInDescFilter,
          noStockFilter,
          partialStockFilter,
          noDescriptionFilter,
          hasStockFilter,
        },
      });
      setProducts(response.data.products);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAllCategories = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/categories`);
      const categoryData = response.data;
      const flatCategories = flattenCategories(categoryData);
      setCategoryOptions(flatCategories);

      // Skapa maps för enkel åtkomst
      const cMap: { [key: number]: CategoryOption } = {};
      const pMap: { [key: number]: number | null } = {};
      flatCategories.forEach((cat) => {
        cMap[cat.categoryId] = cat;
        pMap[cat.categoryId] = cat.parentCategoryId;
      });
      setCategoryMap(cMap);
      setParentMap(pMap);
    } catch (error) {
      console.error(error);
    }
  };

  const flattenCategories = (
    categories: Category[],
    parentPath = '',
    depth = 0,
  ): CategoryOption[] => {
    let flatList: CategoryOption[] = [];

    categories.forEach((category) => {
      const currentPath = parentPath
        ? `${parentPath} > ${category.categoryName}`
        : category.categoryName;

      flatList.push({
        categoryId: category.categoryId,
        categoryName: category.categoryName,
        fullPath: currentPath,
        parentCategoryId: category.parentCategoryId,
        depth: depth,
      });

      if (category.children && category.children.length > 0) {
        flatList = flatList.concat(
          flattenCategories(category.children, currentPath, depth + 1),
        );
      }
    });

    return flatList;
  };

  useEffect(() => {
    if (productCategoryIds && categoryOptions.length > 0) {
      // Mappa produktkategorier till categoryOptions
      const selectedCats = categoryOptions.filter((cat) =>
        productCategoryIds.includes(cat.categoryId),
      );

      // Inkludera föräldrakategorier
      const allSelectedCategoriesMap = new Map<number, CategoryOption>();
      selectedCats.forEach((cat) => {
        allSelectedCategoriesMap.set(cat.categoryId, cat);
        const ancestors = getAncestors(cat.categoryId);
        ancestors.forEach((ancestor) => {
          if (!allSelectedCategoriesMap.has(ancestor.categoryId)) {
            allSelectedCategoriesMap.set(ancestor.categoryId, ancestor);
          }
        });
      });

      const allSelectedCategories = Array.from(
        allSelectedCategoriesMap.values(),
      );

      // Sortera kategorier baserat på djup
      allSelectedCategories.sort((a, b) => a.depth - b.depth);

      setSelectedCategories(allSelectedCategories);
    } else {
      setSelectedCategories([]);
    }
  }, [productCategoryIds, categoryOptions]);

  const getAncestors = (categoryId: number): CategoryOption[] => {
    const ancestors: CategoryOption[] = [];
    let currentCategoryId = categoryId;
    while (
      parentMap[currentCategoryId] !== null &&
      parentMap[currentCategoryId] !== undefined
    ) {
      const parentId = parentMap[currentCategoryId];
      if (parentId !== null && categoryMap[parentId]) {
        ancestors.unshift(categoryMap[parentId]);
        currentCategoryId = parentId;
      } else {
        break;
      }
    }
    return ancestors;
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setPage(0);
  };

  const handleLongDescriptionFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setLongDescriptionFilter(e.target.checked);
    setPage(0);
  };

  const handleNoCategoryFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNoCategoryFilter(e.target.checked);
    setPage(0);
  };

  const handleNoImageFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNoImageFilter(e.target.checked);
    setPage(0);
  };

  const handlePartialImageFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPartialImageFilter(e.target.checked);
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleProductClick = async (product: Product) => {
    try {
      // Återställ activeTab till 0 när en ny produkt öppnas
      setActiveTab(0);

      // Återställ rabatt-inputs innan vi laddar ny produkt
      setDiscountInput({});

      const [
        productResponse,
        skuResponse,
        mediaResponse,
        categoryResponse,
        priceResponse,
        badgeResponse,
      ] = await Promise.all([
        axios.get(`${config.API_URL}/products/${product.productId}`),
        axios.get(`${config.API_URL}/products/${product.productId}/skus`),
        axios.get(`${config.API_URL}/products/${product.productId}/media`),
        axios.get(`${config.API_URL}/products/${product.productId}/categories`),
        axios.get<SKUPrice[]>(
          `${config.API_URL}/products/${product.productId}/skus`,
        ),
        axios.get(`${config.API_URL}/products/${product.productId}/badges`),
      ]);

      const productData = productResponse.data;
      const skuData = skuResponse.data as SKU[];
      const mediaData = mediaResponse.data;
      const productCategories = categoryResponse.data as number[];
      const priceData = priceResponse.data;
      const badgeData = badgeResponse.data;

      // Sätt SKUs
      setSkus(skuData);

      // Sätt SKU priser
      setSkuPrices(priceData);

      // Initiera prisinputs
      const initialInputs: {
        [skuId: number]: { unitPrice: string; unitPriceOriginal: string };
      } = {};
      priceData.forEach((sku: SKUPrice) => {
        initialInputs[sku.skuId] = {
          unitPrice: sku.unitPrice.toString().replace('.', ','),
          unitPriceOriginal: sku.unitPriceOriginal.toString().replace('.', ','),
        };
      });
      setPriceInputs(initialInputs);

      // Hämta produktmedia
      setProductMedia(mediaData);

      // Räkna ut totalColors och colorsWithImages
      const uniqueColors = new Set(skuData.map((sku) => sku.attribute1Code));
      const totalColors = uniqueColors.size;
      const colorsWithImages = Object.keys(mediaData).length;

      // Uppdatera selectedProduct med beräknade värden
      setSelectedProduct({
        ...productData,
        colorsWithImages,
        totalColors,
        hasCategories: productData.hasCategories ?? product.hasCategories,
        hasValidDescription:
          productData.hasValidDescription ?? product.hasValidDescription,
      });

      // Hantera keywords oavsett om det kommer som sträng eller array
      let keywordsArray: string[] = [];
      if (typeof productData.keywords === 'string') {
        keywordsArray = productData.keywords
          .split(',')
          .map((k: string) => k.trim());
      } else if (Array.isArray(productData.keywords)) {
        keywordsArray = productData.keywords;
      }
      setKeywords(keywordsArray.filter((k) => k));

      // Konvertera HTML till DraftJS ContentState
      const html = productData.longDescription || '';
      const contentBlock = htmlToDraft(html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks,
        );
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      } else {
        setEditorState(EditorState.createEmpty());
      }

      // Initiera `productDescription` med befintlig HTML
      setProductDescription(html);

      // Hämta produktkategorier
      setProductCategoryIds(productCategories);

      // Extrahera unika färger från SKUs
      const colors = Array.from(uniqueColors);
      setAvailableColors(colors);

      // Uppdatera hur vi sätter selectedBadges
      // Hitta matchande badges från vår huvudlista av badges
      const productBadges = badges.filter((badge) =>
        badgeData.some(
          (productBadge: Badge) => productBadge.categoryId === badge.categoryId,
        ),
      );

      setSelectedBadges(productBadges);
      setProductBadgeIds(badgeData.map((badge: Badge) => badge.categoryId));
    } catch (error) {
      console.error('Error fetching product details:', error);
      setSnackbarMessage('Ett fel inträffade vid hämtning av produktdata');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleEditorChange = (state: EditorState) => {
    setEditorState(state);
    const content = draftToHtml(convertToRaw(state.getCurrentContent()));
    setProductDescription(content);
  };

  const handleSaveChanges = async () => {
    if (!selectedProduct) return;

    setIsSaving(true);
    try {
      // Samla alla ändringar som ska sparas
      const promises = [];

      // Spara produktdata (beskrivning, kategorier, keywords)
      const dataToSave = {
        longDescription: productDescription,
        categoriesToReplaceExisting: [
          ...selectedCategories.map((cat) => ({
            categoryId: cat.categoryId,
          })),
          ...selectedBadges.map((badge) => ({
            categoryId: badge.categoryId,
          })),
        ],
        keywords: keywords,
      };
      promises.push(
        axios.put(
          `${config.API_URL}/products/${selectedProduct.productId}`,
          dataToSave,
        ),
      );

      // Spara prisuppdateringar (om det finns några)
      if (skuPrices.length > 0) {
        const updatedSkus = skuPrices.map((sku) => {
          const input = priceInputs[sku.skuId];
          return {
            ...sku,
            unitPriceOriginal:
              parseFloat(input?.unitPriceOriginal.replace(',', '.')) ||
              sku.unitPriceOriginal,
            unitPrice:
              parseFloat(input?.unitPrice.replace(',', '.')) || sku.unitPrice,
          };
        });

        promises.push(
          axios.post(
            `${config.API_URL}/products/${selectedProduct.productId}/update-prices`,
            { skus: updatedSkus },
          ),
        );
      }

      // Vänta på att alla sparningar är klara
      await Promise.all(promises);

      setSnackbarMessage('Produktuppgifter har sparats');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);

      handleCloseDialog();
      fetchProducts();
    } catch (error) {
      console.error('Error saving changes:', error);
      setSnackbarMessage('Ett fel inträffade vid sparande av ändringar');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setIsSaving(false);
    }
  };

  const handleCloseDialog = () => {
    setSelectedProduct(null);
    setProductDescription('');
    setSelectedCategories([]);
    setProductCategoryIds([]);
    setEditorState(EditorState.createEmpty());
    setProductMedia({});
    setSkus([]);
    setAvailableColors([]);
    setKeywords([]);
    setPriceInputs({});
    setDiscountInput({}); // Återställ rabatt-inputs
  };

  const handleCategoryChange = (event: any, newValue: CategoryOption[]) => {
    // Automatisk lägga till föräldrakategorier
    const allSelectedCategoriesMap = new Map<number, CategoryOption>();
    newValue.forEach((cat) => {
      allSelectedCategoriesMap.set(cat.categoryId, cat);
      const ancestors = getAncestors(cat.categoryId);
      ancestors.forEach((ancestor) => {
        if (!allSelectedCategoriesMap.has(ancestor.categoryId)) {
          allSelectedCategoriesMap.set(ancestor.categoryId, ancestor);
        }
      });
    });

    // Konvertera map till array
    const allSelectedCategories = Array.from(allSelectedCategoriesMap.values());

    // Sortera kategorier baserat på djup (lägst djup först)
    allSelectedCategories.sort((a, b) => a.depth - b.depth);

    setSelectedCategories(allSelectedCategories);
  };

  // Funktion för att generera beskrivning med AI
  const handleGenerateDescription = async () => {
    if (!selectedProduct) return;

    setIsGenerating(true);
    try {
      const content = draftToHtml(
        convertToRaw(editorState.getCurrentContent()),
      );

      const response = await axios.post(
        `${config.API_URL}/products/${selectedProduct.productId}/generate-description`,
        {
          currentDescription: content,
        },
      );

      let generatedDescription = response.data.generatedDescription;

      // Formatera texten med HTML-paragrafer
      generatedDescription = generatedDescription
        .split('\n\n')
        .filter((paragraph: string) => paragraph.trim() !== '')
        .map((paragraph: string) => `<p>${paragraph.trim()}</p>`)
        .join('');

      const contentBlock = htmlToDraft(generatedDescription);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks,
          contentBlock.entityMap,
        );
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);
        setProductDescription(generatedDescription);
      }
    } catch (error) {
      console.error('Error generating description:', error);
      alert('Ett fel inträffade vid generering av produktbeskrivning.');
    } finally {
      setIsGenerating(false);
    }
  };

  // Hantera förhandsgranskning (processa bilder utan att stnga modal)
  const handlePreview = async (data: UploadData) => {
    try {
      // Skicka data till backend
      const formData = new FormData();
      formData.append('articleNumber', data.articleNumber);
      formData.append('colorCode', data.colorCode);
      formData.append('attribute1Number', data.attribute1Number);
      formData.append(
        'transparentIndices',
        JSON.stringify(data.transparentIndices),
      );
      data.images.forEach((file) => {
        formData.append('images', file, file.name);
      });

      const response = await axios.post(
        `${config.API_URL}/upload/preview`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );

      // Lägg till API_URL till varje bild-URL som kommer tillbaka
      const imageUrls = response.data.imageUrls.map(
        (url: string) => `${config.API_URL}/${url}`,
      );

      setUploadedImages(imageUrls);
    } catch (error) {
      console.error(error);
      alert('Ett fel inträffade vid bearbetning av bilder.');
    }
  };

  const handleUploadSubmit = async (data: UploadData) => {
    try {
      await axios.post(`${config.API_URL}/upload/save`, {
        articleNumber: data.articleNumber,
        colorCode: data.colorCode,
        attribute1Number: data.attribute1Number,
        imageUrls: uploadedImages,
      });

      // Hämta uppdaterad produktinformation inklusive media
      if (selectedProduct) {
        const mediaResponse = await axios.get(
          `${config.API_URL}/products/${selectedProduct.productId}/media`,
        );
        setProductMedia(mediaResponse.data);
      }

      handleCloseUploadModal();
      alert('Bilder har sparats.');
    } catch (error: any) {
      console.error('Upload error details:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
        url: error.config?.url,
        method: error.config?.method,
        data: error.config?.data,
      });

      // Mer specifikt felmeddelande till användaren
      const errorMessage =
        error.response?.data?.message ||
        'Ett fel inträffade vid sparande av bilder';
      alert(errorMessage);
    }
  };

  const handleOpenUploadModal = (sku: SKU) => {
    setSelectedSku(sku);
    setIsUploadModalOpen(true);
    setUploadedImages([]); // Återställ uppladdade bilder
  };

  const handleCloseUploadModal = () => {
    setIsUploadModalOpen(false);
    setSelectedSku(null);
    setUploadedImages([]);
  };

  // Lägg till ny hanterare för filtret
  const handleNoBrandInDescFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNoBrandInDescFilter(e.target.checked);
    setPage(0);
  };

  // Lägg till nya hanterare för filterändringar
  const handleNoStockFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNoStockFilter(e.target.checked);
    setPage(0);
  };

  const handlePartialStockFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPartialStockFilter(e.target.checked);
    setPage(0);
  };

  const [keywords, setKeywords] = useState<string[]>([]);
  const [newKeyword, setNewKeyword] = useState('');

  const handleAddKeyword = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && newKeyword.trim()) {
      if (!keywords.includes(newKeyword.trim())) {
        setKeywords([...keywords, newKeyword.trim()]);
        setNewKeyword('');
      }
    }
  };

  const handleDeleteKeyword = (keywordToDelete: string) => {
    setKeywords(keywords.filter((keyword) => keyword !== keywordToDelete));
  };

  const handleKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewKeyword(event.target.value);
  };

  const [isGeneratingKeywords, setIsGeneratingKeywords] = useState(false);

  const handleGenerateKeywords = async () => {
    if (!selectedProduct) return;

    setIsGeneratingKeywords(true);
    try {
      const content = draftToHtml(
        convertToRaw(editorState.getCurrentContent()),
      );

      const response = await axios.post(
        `${config.API_URL}/products/${selectedProduct.productId}/generate-keywords`,
        {
          currentDescription: content,
        },
      );

      // Kombinera existerande och nya nyckelord, ta bort eventuella dubletter
      const newKeywords = response.data.keywords;
      const combinedKeywords = Array.from(
        new Set([...keywords, ...newKeywords]),
      );
      setKeywords(combinedKeywords);
    } catch (error) {
      console.error('Error generating keywords:', error);
      alert('Ett fel inträffade vid generering av nyckelord.');
    } finally {
      setIsGeneratingKeywords(false);
    }
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success',
  );

  // Lägg till ny hanterare för filtret
  const handleNoDescriptionFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNoDescriptionFilter(e.target.checked);
    setPage(0);
  };

  // Lägg till prishanteringsfunktioner
  const handlePriceChange = (
    skuId: number,
    priceType: 'unitPrice' | 'unitPriceOriginal',
    value: string,
  ) => {
    setPriceInputs((prev) => ({
      ...prev,
      [skuId]: {
        ...prev[skuId],
        [priceType]: value,
      },
    }));
  };

  const handleDiscountChange = (skuId: number, discountValue: string) => {
    setDiscountInput((prev) => ({
      ...prev,
      [skuId]: discountValue,
    }));

    // Om värdet är tomt, sätt försäljningspris till ordinarie pris
    if (!discountValue.trim()) {
      const originalPrice = priceInputs[skuId]?.unitPriceOriginal || '0';
      handlePriceChange(skuId, 'unitPrice', originalPrice);
      return;
    }

    const originalPrice =
      parseFloat(priceInputs[skuId]?.unitPriceOriginal.replace(',', '.')) || 0;

    if (originalPrice && discountValue) {
      const discount = parseFloat(discountValue) / 100;
      if (!isNaN(discount)) {
        const discountedPrice = Math.round(originalPrice * (1 - discount));
        handlePriceChange(skuId, 'unitPrice', discountedPrice.toString());
      }
    }
  };

  // Lägg till useCallback för att hantera intersection
  const handleIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (entry.target === descriptionRef.current) setActiveTab(0);
          else if (entry.target === categoriesRef.current) setActiveTab(1);
          else if (entry.target === mediaRef.current) setActiveTab(2);
          else if (entry.target === pricesRef.current) setActiveTab(3);
        }
      });
    },
    [],
  );

  // Lägg till Intersection Observer
  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5, // Elementet måste vara 50% synligt
      root: document.querySelector(
        '[role="dialog"] > .MuiBox-root > .MuiBox-root',
      ), // Scrollcontainern
    });

    const refs = [descriptionRef, categoriesRef, mediaRef, pricesRef];
    refs.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      refs.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [handleIntersection]);

  const fetchAllBadges = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/categories/badges`);
      setBadges(response.data);
    } catch (error) {
      console.error('Error fetching badges:', error);
      setSnackbarMessage('Ett fel inträffade vid hämtning av badges');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  // Lägg till hanterare för det nya filtret
  const handleHasStockFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setHasStockFilter(e.target.checked);
    setPage(0);
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Produkthantering
      </Typography>
      <TextField
        label="Sök"
        variant="outlined"
        value={search}
        onChange={handleSearchChange}
        sx={{ mb: 2, width: '100%' }}
      />

      <Paper sx={{ p: 2, mb: 2 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={handleExpandClick}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FilterListIcon sx={{ mr: 1 }} />
            <Typography variant="h6">Produktfilter</Typography>
          </Box>
          <ExpandMore
            expand={expanded}
            aria-expanded={expanded}
            aria-label="visa filter"
            sx={{ ml: 'auto' }}
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Box>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              {/* Beskrivningsfilter */}
              <Grid item xs={12} sm={6} md={4}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 2,
                    backgroundColor: 'rgba(0, 0, 0, 0.02)',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <DescriptionIcon sx={{ mr: 1, color: 'primary.main' }} />
                    <Typography variant="subtitle1">Beskrivning</Typography>
                  </Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={longDescriptionFilter}
                        onChange={handleLongDescriptionFilterChange}
                      />
                    }
                    label="Kort produktbeskrivning"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={noDescriptionFilter}
                        onChange={handleNoDescriptionFilterChange}
                      />
                    }
                    label="Saknar beskrivning"
                  />
                </Paper>
              </Grid>

              {/* Kategorifilter */}
              <Grid item xs={12} sm={6} md={4}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 2,
                    backgroundColor: 'rgba(0, 0, 0, 0.02)',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <CategoryIcon sx={{ mr: 1, color: 'primary.main' }} />
                    <Typography variant="subtitle1">Kategorier</Typography>
                  </Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={noCategoryFilter}
                        onChange={handleNoCategoryFilterChange}
                      />
                    }
                    label="Saknar kategori"
                  />
                </Paper>
              </Grid>

              {/* Bildfilter */}
              <Grid item xs={12} sm={6} md={4}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 2,
                    backgroundColor: 'rgba(0, 0, 0, 0.02)',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <ImageIcon sx={{ mr: 1, color: 'primary.main' }} />
                    <Typography variant="subtitle1">Bilder</Typography>
                  </Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={noImageFilter}
                        onChange={handleNoImageFilterChange}
                      />
                    }
                    label="Saknar bilder"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={partialImageFilter}
                        onChange={handlePartialImageFilterChange}
                      />
                    }
                    label="Ofullständiga bilder"
                  />
                </Paper>
              </Grid>

              {/* Varumärkesfilter */}
              <Grid item xs={12} sm={6} md={4}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 2,
                    backgroundColor: 'rgba(0, 0, 0, 0.02)',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <BrandingWatermarkIcon
                      sx={{ mr: 1, color: 'primary.main' }}
                    />
                    <Typography variant="subtitle1">Varumärke</Typography>
                  </Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={noBrandInDescFilter}
                        onChange={handleNoBrandInDescFilterChange}
                      />
                    }
                    label="Saknar varumärke i beskrivning"
                  />
                </Paper>
              </Grid>

              {/* Lagersaldofilter */}
              <Grid item xs={12} sm={6} md={4}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 2,
                    backgroundColor: 'rgba(0, 0, 0, 0.02)',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <InventoryIcon sx={{ mr: 1, color: 'primary.main' }} />
                    <Typography variant="subtitle1">Lagersaldo</Typography>
                  </Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={noStockFilter}
                        onChange={handleNoStockFilterChange}
                      />
                    }
                    label="Saknar lagersaldo"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={partialStockFilter}
                        onChange={handlePartialStockFilterChange}
                      />
                    }
                    label="Ofullständigt lagersaldo"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={hasStockFilter}
                        onChange={handleHasStockFilterChange}
                      />
                    }
                    label="Har lagersaldo"
                  />
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Collapse>
      </Paper>

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        {isLoading && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(18, 18, 18, 0.7)',
              backdropFilter: 'blur(4px)',
              zIndex: 1,
            }}
          >
            <CircularProgress sx={{ color: '#BB86FC' }} />
          </Box>
        )}
        <Table stickyHeader aria-label="Produktlista">
          <TableHead>
            <TableRow>
              <TableCell>Artikelnummer</TableCell>
              <TableCell>Produktnamn</TableCell>
              <TableCell>Varumärke</TableCell>
              <TableCell>Bild</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product, index) => (
              <TableRow
                key={`${product.productId}-${index}`}
                hover
                onClick={() => handleProductClick(product)}
                style={{ cursor: 'pointer' }}
              >
                <TableCell>{product.productNumber}</TableCell>
                <TableCell>{product.productName}</TableCell>
                <TableCell>{product.brandName}</TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {product.mediaUrl ? (
                      <Box
                        sx={{
                          width: 40,
                          height: 40,
                          borderRadius: 1,
                          overflow: 'hidden',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: 'lightgray',
                        }}
                      >
                        <SmartImage
                          baseUrl="https://media.viskanassets.com/v2/enghssportprod/original/"
                          imageName={product.mediaUrl}
                          alt={product.productName}
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                          }}
                        />
                      </Box>
                    ) : (
                      <ImageIcon
                        sx={{ color: 'text.disabled', width: 40, height: 40 }}
                      />
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <TablePagination
        component="div"
        count={totalPages * rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Dialog för produktdetaljer */}
      <Dialog
        open={!!selectedProduct}
        onClose={handleCloseDialog}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: {
            height: '90vh',
            maxHeight: '90vh',
            display: 'flex',
            flexDirection: 'row',
            m: 0,
          },
        }}
      >
        {isSaving && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(18, 18, 18, 0.9)',
              backdropFilter: 'blur(4px)',
              zIndex: 9999,
              borderRadius: '4px',
            }}
          >
            <CircularProgress sx={{ color: '#BB86FC' }} />
          </Box>
        )}

        {/* Sidopanel */}
        <Box
          sx={{
            width: 240,
            minWidth: 240,
            maxWidth: 240,
            borderRight: 1,
            borderColor: 'divider',
            display: 'flex',
            flexDirection: 'column',
            bgcolor: 'background.paper',
            position: 'sticky',
            left: 0,
            height: '100%',
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              p: 2,
              borderBottom: 1,
              borderColor: 'divider',
              overflow: 'hidden',
            }}
          >
            <Typography variant="subtitle2" color="textSecondary" gutterBottom>
              Artikelnummer
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {selectedProduct?.productNumber}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary" gutterBottom>
              Produktnamn
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {selectedProduct?.productName}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary" gutterBottom>
              Varumärke
            </Typography>
            <Typography
              variant="body1"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {selectedProduct?.brandName}
            </Typography>

            {/* Statusindikator */}
            <Box sx={{ mb: 2 }}>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                gutterBottom
              >
                Status
              </Typography>
              <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                <Chip
                  size="small"
                  icon={<DescriptionIcon />}
                  label={
                    selectedProduct?.hasValidDescription === 1
                      ? 'Beskrivning OK'
                      : 'För kort beskrivning'
                  }
                  color={
                    selectedProduct?.hasValidDescription === 1
                      ? 'success'
                      : 'error'
                  }
                />
                <Chip
                  size="small"
                  icon={<CategoryIcon />}
                  label={
                    selectedProduct?.hasCategories === 1
                      ? 'Kategorier OK'
                      : 'Saknar kategorier'
                  }
                  color={
                    selectedProduct?.hasCategories === 1 ? 'success' : 'error'
                  }
                />
                <Chip
                  size="small"
                  icon={<ImageIcon />}
                  label={
                    !selectedProduct?.totalColors ||
                    selectedProduct?.totalColors === 0
                      ? 'Saknar färger'
                      : selectedProduct?.colorsWithImages === 0
                        ? 'Saknar bilder'
                        : selectedProduct?.colorsWithImages ===
                            selectedProduct?.totalColors
                          ? 'Alla färger har bilder'
                          : `Bilder ${selectedProduct?.colorsWithImages}/${selectedProduct?.totalColors}`
                  }
                  color={
                    !selectedProduct?.totalColors ||
                    selectedProduct?.totalColors === 0
                      ? 'error'
                      : selectedProduct?.colorsWithImages === 0
                        ? 'error'
                        : selectedProduct?.colorsWithImages ===
                            selectedProduct?.totalColors
                          ? 'success'
                          : 'warning'
                  }
                />
              </Box>
            </Box>
          </Box>

          <List sx={{ flexGrow: 0 }}>
            <ListItem
              sx={{
                cursor: 'pointer',
                bgcolor: activeTab === 0 ? 'action.selected' : 'transparent',
                '&:hover': {
                  bgcolor: activeTab === 0 ? 'action.selected' : 'action.hover',
                },
              }}
              onClick={(e) => handleTabChange(e, 0)}
            >
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary="Beskrivning" />
            </ListItem>
            <ListItem
              sx={{
                cursor: 'pointer',
                bgcolor: activeTab === 1 ? 'action.selected' : 'transparent',
                '&:hover': {
                  bgcolor: activeTab === 1 ? 'action.selected' : 'action.hover',
                },
              }}
              onClick={(e) => handleTabChange(e, 1)}
            >
              <ListItemIcon>
                <CategoryIcon />
              </ListItemIcon>
              <ListItemText primary="Kategorier" />
            </ListItem>
            <ListItem
              sx={{
                cursor: 'pointer',
                bgcolor: activeTab === 2 ? 'action.selected' : 'transparent',
                '&:hover': {
                  bgcolor: activeTab === 2 ? 'action.selected' : 'action.hover',
                },
              }}
              onClick={(e) => handleTabChange(e, 2)}
            >
              <ListItemIcon>
                <LocalOfferIcon />
              </ListItemIcon>
              <ListItemText primary="Badges" />
            </ListItem>
            <ListItem
              sx={{
                cursor: 'pointer',
                bgcolor: activeTab === 3 ? 'action.selected' : 'transparent',
                '&:hover': {
                  bgcolor: activeTab === 3 ? 'action.selected' : 'action.hover',
                },
              }}
              onClick={(e) => handleTabChange(e, 3)}
            >
              <ListItemIcon>
                <ImageIcon />
              </ListItemIcon>
              <ListItemText primary="Media & Lager" />
            </ListItem>
            <ListItem
              sx={{
                cursor: 'pointer',
                bgcolor: activeTab === 4 ? 'action.selected' : 'transparent',
                '&:hover': {
                  bgcolor: activeTab === 4 ? 'action.selected' : 'action.hover',
                },
              }}
              onClick={(e) => handleTabChange(e, 4)}
            >
              <ListItemIcon>
                <MonetizationOnIcon />
              </ListItemIcon>
              <ListItemText primary="Priser" />
            </ListItem>
          </List>

          <Box
            sx={{
              mt: 'auto',
              p: 2,
              borderTop: 1,
              borderColor: 'divider',
              flexShrink: 0,
            }}
          >
            <Button
              onClick={handleCloseDialog}
              sx={{
                mb: 1,
                width: '100%',
              }}
            >
              Avbryt
            </Button>
            <Button
              onClick={handleSaveChanges}
              color="primary"
              variant="contained"
              disabled={isSaving}
              sx={{ width: '100%' }}
            >
              {isSaving ? 'Sparar...' : 'Spara'}
            </Button>
          </Box>
        </Box>

        {/* Huvudinnehåll */}
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            minWidth: 0,
          }}
        >
          <Box sx={{ p: 3, flexGrow: 1, overflowY: 'auto' }}>
            {/* Beskrivningssektion */}
            <Box ref={descriptionRef} sx={{ mb: 6, pt: 2 }}>
              <Typography variant="h6" gutterBottom>
                Produktbeskrivning
              </Typography>
              <Box sx={{ position: 'relative', mb: 4 }}>
                <IconButton
                  onClick={handleGenerateDescription}
                  color="secondary"
                  sx={{
                    position: 'absolute',
                    bottom: 10,
                    right: 10,
                    zIndex: 1,
                    transition: 'all 0.2s ease-in-out',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.1)',
                      transform: 'scale(1.3)',
                    },
                  }}
                  disabled={isGenerating}
                >
                  {isGenerating ? (
                    <CircularProgress size={24} />
                  ) : (
                    <SmartToyIcon />
                  )}
                </IconButton>
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={handleEditorChange}
                  editorStyle={{
                    border: '1px solid rgba(0, 0, 0, 0.23)',
                    borderRadius: '4px',
                    minHeight: '300px',
                    padding: '10px 50px 40px 14px',
                  }}
                  toolbar={{
                    options: ['inline', 'blockType', 'list', 'link'],
                    inline: {
                      options: ['bold', 'italic', 'underline'],
                    },
                    blockType: {
                      options: ['Normal', 'H2', 'H3'],
                    },
                  }}
                />
              </Box>

              <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
                Nyckelord
              </Typography>
              <Paper sx={{ p: 2 }}>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
                  {Array.isArray(keywords) &&
                    keywords.map((keyword) => (
                      <Chip
                        key={keyword}
                        label={keyword}
                        onDelete={() => handleDeleteKeyword(keyword)}
                        size="small"
                        sx={{
                          '& .MuiChip-deleteIcon': {
                            display: 'none',
                          },
                          '&:hover .MuiChip-deleteIcon': {
                            display: 'block',
                          },
                        }}
                      />
                    ))}
                </Box>
                <Box sx={{ position: 'relative' }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={newKeyword}
                    onChange={handleKeywordChange}
                    onKeyPress={handleAddKeyword}
                    placeholder="Skriv ett nyckelord och tryck Enter"
                  />
                  <IconButton
                    onClick={handleGenerateKeywords}
                    disabled={isGeneratingKeywords}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: '50%',
                      transform: 'translateY(-50%)',
                    }}
                  >
                    {isGeneratingKeywords ? (
                      <CircularProgress size={24} />
                    ) : (
                      <SmartToyIcon color="secondary" />
                    )}
                  </IconButton>
                </Box>
              </Paper>
            </Box>

            {/* Kategorisektion */}
            <Box ref={categoriesRef} sx={{ mb: 6, pt: 4 }}>
              <Typography variant="h6" gutterBottom>
                Kategorier
              </Typography>
              <Paper sx={{ p: 2 }}>
                <Autocomplete
                  multiple
                  options={categoryOptions}
                  getOptionLabel={(option) =>
                    option.fullPath || option.categoryName || ''
                  }
                  value={selectedCategories}
                  onChange={handleCategoryChange}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      const { key, ...restTagProps } = getTagProps({ index });
                      return (
                        <Chip
                          key={option.categoryId}
                          label={option.fullPath || option.categoryName}
                          size="small"
                          {...restTagProps}
                        />
                      );
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Kategorier"
                      placeholder="Välj kategorier"
                    />
                  )}
                  noOptionsText="Inga kategorier tillgängliga"
                />
              </Paper>

              <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
                Badges
              </Typography>
              <Paper sx={{ p: 2 }}>
                <Autocomplete
                  multiple
                  options={badges}
                  getOptionLabel={(option) => option.categoryName || ''}
                  value={selectedBadges}
                  onChange={(event, newValue) => {
                    setSelectedBadges(newValue);
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      const { key, ...restTagProps } = getTagProps({ index });
                      return (
                        <Chip
                          key={option.categoryId}
                          label={option.categoryName || ''}
                          size="small"
                          {...restTagProps}
                        />
                      );
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Badges"
                      placeholder="Välj badges"
                    />
                  )}
                  noOptionsText="Inga badges tillgängliga"
                />
              </Paper>
            </Box>

            {/* Media & Lagersektion */}
            <Box ref={mediaRef} sx={{ mb: 6, pt: 4 }}>
              <Typography variant="h6" gutterBottom>
                Media & Lager
              </Typography>
              <Grid container spacing={2}>
                {skus.map((sku) => (
                  <Grid item xs={12} md={6} key={sku.attribute1Number}>
                    <Paper sx={{ p: 2 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                          mb: 2,
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: 'bold' }}
                        >
                          Färg: {sku.attribute1Code}
                        </Typography>
                        <Box
                          component="div"
                          sx={{
                            display: 'flex',
                            gap: 2,
                            ml: 'auto',
                          }}
                        >
                          {selectedProduct?.stockByColor && (
                            <Chip
                              size="small"
                              icon={<InventoryIcon />}
                              label={
                                (selectedProduct.stockByColor[
                                  sku.attribute1Number
                                ]?.stockBalance ?? 0) > 0
                                  ? `${selectedProduct.stockByColor[sku.attribute1Number].stockBalance} st`
                                  : 'Ej i lager'
                              }
                              color={
                                (selectedProduct.stockByColor[
                                  sku.attribute1Number
                                ]?.stockBalance ?? 0) > 0
                                  ? 'success'
                                  : 'error'
                              }
                            />
                          )}
                          {sku.missingPrice === true && (
                            <Chip
                              size="small"
                              icon={<WarningIcon />}
                              label="Saknar pris"
                              color="warning"
                            />
                          )}
                        </Box>
                      </Box>

                      {productMedia[sku.attribute1Number] &&
                      productMedia[sku.attribute1Number].length > 0 ? (
                        <ImageList
                          cols={4}
                          gap={4}
                          sx={{ width: '100%', height: 'auto' }}
                        >
                          {productMedia[sku.attribute1Number].map(
                            (imageName, index) => (
                              <Box
                                key={index}
                                sx={{
                                  height: 100,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  padding: 0,
                                  backgroundColor: 'lightgray',
                                  borderRadius: '8px',
                                  overflow: 'hidden',
                                }}
                              >
                                <SmartImage
                                  baseUrl="https://media.viskanassets.com/v2/enghssportprod/original/"
                                  imageName={imageName}
                                  alt={`Bild ${index + 1}`}
                                  loading="lazy"
                                  style={{
                                    maxHeight: '100%',
                                    maxWidth: '100%',
                                    objectFit: 'contain',
                                  }}
                                />
                              </Box>
                            ),
                          )}
                        </ImageList>
                      ) : (
                        <Box
                          sx={{
                            height: 100,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'background.paper',
                            borderRadius: 1,
                            flexDirection: 'column',
                            cursor: 'pointer',
                            border: '1px dashed',
                            borderColor: 'divider',
                            transition: 'all 0.2s ease-in-out',
                            '&:hover': {
                              borderColor: 'primary.main',
                              backgroundColor: 'action.hover',
                              '& .MuiSvgIcon-root': {
                                color: 'primary.main',
                              },
                              '& .MuiTypography-root': {
                                color: 'primary.main',
                              },
                            },
                          }}
                          onClick={() => handleOpenUploadModal(sku)}
                        >
                          <AddCircleOutlineIcon
                            sx={{
                              fontSize: 40,
                              color: 'text.secondary',
                              mb: 1,
                              transition: 'color 0.2s ease-in-out',
                            }}
                          />
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                              transition: 'color 0.2s ease-in-out',
                              px: 2,
                              textAlign: 'center',
                            }}
                          >
                            Ingen media kopplad. Lägg till media
                          </Typography>
                        </Box>
                      )}
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Box>

            {/* Prissektion */}
            <Box ref={pricesRef} sx={{ mb: 6, pt: 4 }}>
              <Typography variant="h6" gutterBottom>
                Prishantering
              </Typography>
              <Paper sx={{ p: 2 }}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Färg</TableCell>
                        <TableCell>Ordinarie Pris</TableCell>
                        <TableCell>Rabatt %</TableCell>
                        <TableCell>Försäljningspris</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {skuPrices.map((sku) => (
                        <TableRow key={sku.skuId}>
                          <TableCell>{sku.attribute1Code}</TableCell>
                          <TableCell>
                            <TextField
                              value={
                                priceInputs[sku.skuId]?.unitPriceOriginal || ''
                              }
                              variant="outlined"
                              size="small"
                              label="SEK"
                              onChange={(e) =>
                                handlePriceChange(
                                  sku.skuId,
                                  'unitPriceOriginal',
                                  e.target.value,
                                )
                              }
                              inputProps={{
                                inputMode: 'decimal',
                                pattern: '^[0-9]*[.,]?[0-9]*$',
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              value={discountInput[sku.skuId] || ''}
                              variant="outlined"
                              size="small"
                              label="%"
                              onChange={(e) =>
                                handleDiscountChange(sku.skuId, e.target.value)
                              }
                              inputProps={{
                                inputMode: 'decimal',
                                pattern: '^[0-9]*[.,]?[0-9]*$',
                                style: { width: '80px' },
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              value={priceInputs[sku.skuId]?.unitPrice || ''}
                              variant="outlined"
                              size="small"
                              label="SEK"
                              onChange={(e) =>
                                handlePriceChange(
                                  sku.skuId,
                                  'unitPrice',
                                  e.target.value,
                                )
                              }
                              inputProps={{
                                inputMode: 'decimal',
                                pattern: '^[0-9]*[.,]?[0-9]*$',
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          </Box>
        </Box>
      </Dialog>

      {/* Upload Media Modal */}
      {selectedSku && (
        <UploadMediaModal
          open={isUploadModalOpen}
          onClose={handleCloseUploadModal}
          onSubmit={handleUploadSubmit}
          uploadedImages={uploadedImages}
          handlePreview={handlePreview}
          productNumber={selectedProduct?.productNumber || ''}
          availableColors={availableColors}
          initialColorCode={selectedSku.attribute1Code}
          attribute1Number={selectedSku.attribute1Number}
        />
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ProductList;
