import React, { useState, useEffect, useMemo } from 'react';
import {
  Container,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  IconButton,
  Alert,
} from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { sv } from 'date-fns/locale/sv';
import enUS from 'date-fns/locale/en-US';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
  PieChart,
  Pie,
  Legend,
} from 'recharts';
import {
  MonetizationOn,
  ShoppingCart,
  ShowChart,
  BarChart,
  EmojiEvents,
} from '@mui/icons-material';
import axios from 'axios';
import config from '../config';
import { motion } from 'framer-motion';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

interface DailyStat {
  saleDate: string;
  totalAmount: number;
  orderCount: number;
  source: 'online' | 'retail';
  currencyCode: string;
}

interface TopBrand {
  brandName: string;
  totalRevenue: string;
  totalUnitsSold: number;
  sources: string[];
}

interface TopProduct {
  productId: number;
  productNumber: string;
  productName: string;
  brandName: string;
  totalRevenue: string;
  totalUnitsSold: number;
  sources: string[];
}

interface SalesStats {
  dailyStats: DailyStat[];
  topBrands: TopBrand[];
  topProducts: TopProduct[];
}

interface CategoryPerformance {
  mainCategoryId: number;
  mainCategoryName: string;
  subCategories: {
    categoryId: number;
    categoryName: string;
    uniqueProducts: number;
    totalSold: number;
    totalRevenue: string;
    currencyCode: string;
    hasSubCategories: boolean;
  }[];
}

interface CategoryDetails {
  categoryId: number;
  categoryName: string;
  subCategoryId: number;
  subCategoryName: string;
  uniqueProducts: number;
  totalSold: number;
  totalRevenue: string;
  currencyCode: string;
}

const svWithEnUS = {
  ...sv,
  enUS: enUS.enUS,
};

// Custom tooltip komponent
const CustomTooltip = ({ active, payload, label, salesType }: any) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    const showDetailedBreakdown = salesType === 'all';

    return (
      <Box
        sx={{
          backgroundColor: 'rgba(30, 30, 30, 0.95)',
          border: '1px solid rgba(255, 255, 255, 0.1)',
          borderRadius: '4px',
          padding: '12px',
        }}
      >
        <Typography variant="body2" sx={{ mb: 1 }}>
          {new Date(label).toLocaleDateString('sv-SE', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: '#BB86FC', fontWeight: 'bold' }}
        >
          Total:{' '}
          {data.totalAmount.toLocaleString('sv-SE', {
            style: 'currency',
            currency: 'SEK',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
        </Typography>
        {showDetailedBreakdown && (
          <>
            <Typography variant="body2" sx={{ color: '#03DAC6' }}>
              E-handel:{' '}
              {data.onlineAmount.toLocaleString('sv-SE', {
                style: 'currency',
                currency: 'SEK',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </Typography>
            <Typography variant="body2" sx={{ color: '#CF6679' }}>
              Butik:{' '}
              {data.retailAmount.toLocaleString('sv-SE', {
                style: 'currency',
                currency: 'SEK',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </Typography>
          </>
        )}
      </Box>
    );
  }
  return null;
};

// Skapa en custom tooltip komponent för pie charts
const CustomPieTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <Box
        sx={{
          backgroundColor: 'rgba(30, 30, 30, 0.95)',
          border: '1px solid rgba(255, 255, 255, 0.1)',
          borderRadius: '4px',
          padding: '12px',
        }}
      >
        <Typography variant="body2" sx={{ mb: 1 }}>
          {payload[0].name}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: '#BB86FC',
            fontWeight: 'bold',
          }}
        >
          {Number(payload[0].value).toLocaleString('sv-SE', {
            style: 'currency',
            currency: 'SEK',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
        </Typography>
      </Box>
    );
  }
  return null;
};

// Lägg till en ny typ för snabbvalen
type DatePreset = {
  label: string;
  getValue: () => { start: Date; end: Date };
};

// Lägg till snabbvalsalternativen
const datePresets: DatePreset[] = [
  {
    label: 'Senaste 7 dagarna',
    getValue: () => ({
      start: new Date(new Date().setDate(new Date().getDate() - 6)),
      end: new Date(),
    }),
  },
  {
    label: 'Denna månad',
    getValue: () => ({
      start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      end: new Date(),
    }),
  },
  {
    label: 'Förra månaden',
    getValue: () => ({
      start: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
      end: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
    }),
  },
  {
    label: 'Detta året',
    getValue: () => ({
      start: new Date(new Date().getFullYear(), 0, 1), // 1 januari nuvarande år
      end: new Date(),
    }),
  },
];

const SalesDashboard = () => {
  // Behåll alla hooks i början av komponenten
  const [startDate, setStartDate] = useState<Date | undefined>(() => {
    const date = new Date();
    date.setDate(date.getDate() - 6);
    return date;
  });
  const [endDate, setEndDate] = useState<Date | undefined>(() => new Date());
  const [stats, setStats] = useState<SalesStats | null>(null);
  const [categoryStats, setCategoryStats] = useState<CategoryPerformance[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const [categoryDetails, setCategoryDetails] = useState<CategoryDetails[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [brandSearch, setBrandSearch] = useState('');
  const [salesType, setSalesType] = useState<'all' | 'online' | 'retail'>(
    'all',
  );
  const [brandSortBy, setBrandSortBy] = useState<'revenue' | 'units'>(
    'revenue',
  );

  // Memoized values
  const filteredBrands = useMemo(() => {
    if (!brandSearch.trim() || !stats?.topBrands) return stats?.topBrands;
    return stats.topBrands.filter((brand) =>
      brand.brandName.toLowerCase().includes(brandSearch.toLowerCase()),
    );
  }, [stats?.topBrands, brandSearch]);

  const chartData = useMemo(() => {
    if (!stats?.dailyStats) return [];

    // Skapa en Map med alla unika datum
    const dateMap = new Map();

    stats.dailyStats.forEach((stat) => {
      if (!dateMap.has(stat.saleDate)) {
        dateMap.set(stat.saleDate, {
          saleDate: stat.saleDate,
          totalAmount: 0,
          onlineAmount: 0,
          retailAmount: 0,
        });
      }

      const entry = dateMap.get(stat.saleDate);
      if (stat.source === 'online') {
        entry.onlineAmount += Number(stat.totalAmount);
      } else {
        entry.retailAmount += Number(stat.totalAmount);
      }
      entry.totalAmount = entry.onlineAmount + entry.retailAmount;
    });

    // Konvertera Map till array och sortera efter datum
    return Array.from(dateMap.values()).sort(
      (a, b) => new Date(a.saleDate).getTime() - new Date(b.saleDate).getTime(),
    );
  }, [stats?.dailyStats]);

  const handleStartDateChange = (date: Date | null) => {
    console.log('handleStartDateChange:', date);
    if (date) {
      setStartDate(date);
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    console.log('handleEndDateChange:', date);
    if (date) {
      setEndDate(date);
    }
  };

  const fetchStats = async () => {
    console.log('fetchStats körs med:', {
      startDate,
      endDate,
      salesType,
      token: localStorage.getItem('token'),
    });

    try {
      if (!startDate || !endDate) {
        setError('Vänligen välj både start- och slutdatum');
        return;
      }

      setIsLoading(true);
      setError(null);

      const formattedStartDate = startDate.toISOString().split('T')[0];
      const formattedEndDate = endDate.toISOString().split('T')[0];

      console.log('Gör API-anrop till:', `${config.API_URL}/sales/stats`);

      const response = await axios.get(`${config.API_URL}/sales/stats`, {
        params: {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          salesType: salesType,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });

      // Detaljerad loggning av API-svaret
      console.log('Fullständigt API-svar:', response.data);
      console.log('Försäljningsdata:', response.data.salesData);
      console.log('Varumärkesdata:', response.data.brandPerformance);
      console.log('Produktdata:', response.data.topProducts);

      // Logga formateringen
      const formattedStats = {
        dailyStats: response.data.salesData.map((item: any) => ({
          saleDate: item.saleDate.split('T')[0],
          totalAmount: item.totalAmount,
          orderCount: item.orderCount,
          source: item.source,
          currencyCode: item.currencyCode,
        })),
        topBrands:
          response.data.brandPerformance?.map((brand: any) => ({
            brandName: brand.brandName,
            totalSold: parseInt(brand.totalUnitsSold),
            totalRevenue: (
              parseFloat(brand.avgPricePerUnit) * parseInt(brand.totalUnitsSold)
            ).toString(),
            currencyCode: brand.currencyCode,
          })) || [],
        topProducts: response.data.topProducts || [], // Lägg till denna rad
      };

      console.log('Formaterad statistik:', formattedStats);

      setStats(formattedStats);
    } catch (error) {
      console.error('Ett fel uppstod i fetchStats:', error);
      if (axios.isAxiosError(error)) {
        const errorMessage =
          error.response?.data?.error ||
          'Ett fel uppstod vid hämtning av statistik';
        setError(errorMessage);

        // Visa felmeddelande för användaren
        if (error.response?.status === 500) {
          setError(
            'Ett serverfel uppstod. Vänligen försök igen senare eller kontakta support om problemet kvarstår.',
          );
        }
      } else {
        setError('Ett oväntat fel uppstod');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCategoryStats = async () => {
    try {
      if (!startDate || !endDate) {
        console.warn('Datum saknas för kategorihämtning');
        return;
      }

      const response = await axios.get(
        `${config.API_URL}/sales/extended-stats`,
        {
          params: {
            startDate: startDate.toISOString().split('T')[0],
            endDate: endDate.toISOString().split('T')[0],
          },
        },
      );
      console.log('Kategoristatistik:', response.data);
      setCategoryStats(response.data.categoryPerformance);
    } catch (error) {
      console.error('Fel vid hämtning av kategoristatistik:', error);
      if (axios.isAxiosError(error)) {
        console.error('API-fel detaljer:', error.response?.data);
      }
    }
  };

  const fetchCategoryDetails = async (categoryId: number) => {
    try {
      const response = await axios.get(
        `${config.API_URL}/sales/category-details/${categoryId}`,
        {
          params: {
            startDate: startDate?.toISOString().split('T')[0],
            endDate: endDate?.toISOString().split('T')[0],
          },
        },
      );
      console.log('Kategoridetaljer:', response.data);
      setCategoryDetails(response.data.categoryDetails);
      setIsDialogOpen(true);
    } catch (error) {
      console.error('Fel vid hämtning av kategoridetaljer:', error);
      if (axios.isAxiosError(error)) {
        console.error('API-fel detaljer:', error.response?.data);
      }
    }
  };

  const fetchTopPerformers = async () => {
    try {
      if (!startDate || !endDate) return;

      const response = await axios.get(
        `${config.API_URL}/sales/top-performers`,
        {
          params: {
            startDate: startDate.toISOString().split('T')[0],
            endDate: endDate.toISOString().split('T')[0],
            salesType: salesType,
            limit: 100,
            sortBy: brandSortBy,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
      );

      console.log('Top performers data:', response.data);

      setStats((prevStats) => {
        if (!prevStats) return null;
        return {
          ...prevStats,
          topBrands: response.data.topBrands,
          topProducts: response.data.topProducts,
        };
      });
    } catch (error) {
      console.error('Fel vid hämtning av top performers:', error);
      if (axios.isAxiosError(error)) {
        setError(
          error.response?.data?.error ||
            'Ett fel uppstod vid hämtning av statistik',
        );
      }
    }
  };

  useEffect(() => {
    console.log('useEffect för fetchStats körs med:', {
      startDate,
      endDate,
      salesType,
    });

    if (startDate && endDate) {
      fetchStats();
      fetchTopPerformers();
    }
  }, [startDate, endDate, salesType, brandSortBy]);

  useEffect(() => {
    // Lägg till kontroll innan anrop
    if (startDate && endDate) {
      fetchCategoryStats();
    }
  }, [startDate, endDate]);

  if (isLoading) {
    return (
      <Container>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  // Uppdatera beräkningarna för statistiken
  const calculateStats = () => {
    if (!stats?.dailyStats?.length) {
      return {
        totalSales: 0,
        totalOrders: 0,
        averageOrderValue: 0,
        bestDay: {
          saleDate: '',
          totalAmount: 0,
          orderCount: 0,
          source: 'online',
          currencyCode: 'SEK',
        },
        dailyStats: [],
      };
    }

    const salesByDate = stats.dailyStats.reduce(
      (acc: { [key: string]: any }, curr) => {
        const date = curr.saleDate;
        if (!acc[date]) {
          acc[date] = {
            saleDate: date,
            totalAmount: 0,
            orderCount: 0,
            online: { totalAmount: 0, orderCount: 0 },
            retail: { totalAmount: 0, orderCount: 0 },
          };
        }

        if (curr.source === 'online') {
          acc[date].online.totalAmount += Number(curr.totalAmount);
          acc[date].online.orderCount += Number(curr.orderCount);
        } else {
          acc[date].retail.totalAmount += Number(curr.totalAmount);
          acc[date].retail.orderCount += Number(curr.orderCount);
        }

        acc[date].totalAmount += Number(curr.totalAmount);
        acc[date].orderCount += Number(curr.orderCount);
        return acc;
      },
      {},
    );

    const dailyStats = Object.values(salesByDate);

    // Beräkna totaler
    const totalSales = dailyStats.reduce(
      (sum, day) => sum + day.totalAmount,
      0,
    );
    const totalOrders = dailyStats.reduce(
      (sum, day) => sum + day.orderCount,
      0,
    );
    const averageOrderValue = totalOrders ? totalSales / totalOrders : 0;

    // Hitta bästa försäljningsdagen med null-check
    const bestDay =
      dailyStats.length > 0
        ? dailyStats.reduce(
            (best, current) =>
              current.totalAmount > (best?.totalAmount || 0) ? current : best,
            dailyStats[0],
          )
        : {
            saleDate: '',
            totalAmount: 0,
            orderCount: 0,
            source: 'online',
            currencyCode: 'SEK',
          };

    return {
      totalSales,
      totalOrders,
      averageOrderValue,
      bestDay,
      dailyStats,
    };
  };

  const statsData = calculateStats();

  // Beräkna försäljning per land
  const salesByCountry = stats?.dailyStats.reduce(
    (acc: { [key: string]: number }, curr) => {
      const country = curr.currencyCode === 'SEK' ? 'Sverige' : 'Danmark';
      acc[country] = (acc[country] || 0) + Number(curr.totalAmount);
      return acc;
    },
    {},
  );

  // Formatera data för cirkeldiagrammet
  const pieChartData = salesByCountry
    ? Object.entries(salesByCountry).map(([country, amount]) => ({
        name: country,
        value: amount,
      }))
    : [];

  // Uppdatera COLORS arrayen för fler nyanser
  const COLORS = [
    '#BB86FC',
    '#03DAC6',
    '#CF6679',
    '#985EFF',
    '#FFB86C',
    '#FF79C6',
    '#50FA7B',
    '#8BE9FD',
    '#BD93F9',
    '#FF5555',
  ];

  // Beräkna ny statistik
  const calculateExtendedStats = () => {
    if (!stats?.dailyStats.length) return null;

    const numberOfDays = stats.dailyStats.length;
    const dailyAverage = statsData?.totalSales
      ? statsData.totalSales / numberOfDays
      : 0;

    // Hitta bästa försäljningsdagen
    const bestDay = [...stats.dailyStats].sort(
      (a, b) => Number(b.totalAmount) - Number(a.totalAmount),
    )[0];

    // Beräkna försäljning per veckodag
    const salesByWeekday = stats.dailyStats.reduce(
      (acc: { [key: string]: number }, curr) => {
        const weekday = new Date(curr.saleDate).toLocaleDateString('sv-SE', {
          weekday: 'long',
        });
        acc[weekday] = (acc[weekday] || 0) + Number(curr.totalAmount);
        return acc;
      },
      {},
    );

    return {
      dailyAverage,
      bestDay,
      salesByWeekday,
    };
  };

  const extendedStats = calculateExtendedStats();

  // Lägg till denna hjälpfunktion för att formatera data för pie charts
  const formatCategoryData = (category: CategoryPerformance) => {
    return category.subCategories
      .map((sub) => ({
        name: sub.categoryName,
        value: parseFloat(sub.totalRevenue),
        categoryId: sub.categoryId,
        hasSubCategories: sub.hasSubCategories,
      }))
      .filter((item) => !isNaN(item.value) && item.value > 0);
  };

  // Lägg till efter datumväljaren
  const salesTypeButtons = (
    <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
      <Button
        variant={salesType === 'all' ? 'contained' : 'outlined'}
        onClick={() => setSalesType('all')}
        size="small"
      >
        Alla försäljningar
      </Button>
      <Button
        variant={salesType === 'online' ? 'contained' : 'outlined'}
        onClick={() => setSalesType('online')}
        size="small"
      >
        E-handel
      </Button>
      <Button
        variant={salesType === 'retail' ? 'contained' : 'outlined'}
        onClick={() => setSalesType('retail')}
        size="small"
      >
        Fysisk butik
      </Button>
    </Box>
  );

  return (
    <Container maxWidth={false} sx={{ p: 0 }}>
      {error && (
        <Alert
          severity="error"
          sx={{
            mb: 2,
            backgroundColor: 'rgba(255, 50, 50, 0.1)',
            color: '#ff6b6b',
          }}
        >
          {error}
        </Alert>
      )}

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Box
          sx={{
            mt: 4,
            mb: 6,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box>
            <Typography
              variant="h1"
              sx={{
                fontSize: '2.5rem',
                fontWeight: 600,
                background: 'linear-gradient(45deg, #BB86FC, #03DAC6, #CF6679)',
                backgroundSize: '200% 200%',
                animation: 'gradient 15s ease infinite',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              Försäljningsstatistik
            </Typography>
            <Typography
              variant="body2"
              sx={{
                mt: 1,
                color: 'text.secondary',
                fontSize: '0.875rem',
                opacity: 0.8,
              }}
            >
              Statistiken uppdateras dagligen kl. 02:00.
              <br />
              Alla belopp är inklusive moms.
            </Typography>
          </Box>

          <Box>
            <Typography
              variant="subtitle1"
              sx={{
                mb: 1,
                color: 'text.secondary',
                textAlign: 'right',
              }}
            >
              Välj period
            </Typography>
            <Box
              sx={{
                mb: 4,
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                flexWrap: 'wrap',
              }}
            >
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => date && setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="yyyy-MM-dd"
                  customInput={
                    <TextField
                      size="small"
                      label="Från datum"
                      sx={{ width: 150 }}
                    />
                  }
                />
                <DatePicker
                  selected={endDate}
                  onChange={(date) => date && setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  dateFormat="yyyy-MM-dd"
                  customInput={
                    <TextField
                      size="small"
                      label="Till datum"
                      sx={{ width: 150 }}
                    />
                  }
                />
              </Box>

              <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                {datePresets.map((preset) => (
                  <Button
                    key={preset.label}
                    size="small"
                    variant="outlined"
                    onClick={() => {
                      console.log('Snabbval klickat:', preset.label);
                      const { start, end } = preset.getValue();
                      setStartDate(start);
                      setEndDate(end);
                    }}
                    sx={{
                      borderColor: 'rgba(187, 134, 252, 0.3)',
                      color: 'text.secondary',
                      '&:hover': {
                        borderColor: '#BB86FC',
                        backgroundColor: 'rgba(187, 134, 252, 0.08)',
                      },
                    }}
                  >
                    {preset.label}
                  </Button>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </motion.div>

      {salesTypeButtons}

      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={2.4}>
          <motion.div
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.2 }}
          >
            <Card
              sx={{
                height: '100%',
                background: 'linear-gradient(135deg, #BB86FC20, #BB86FC10)',
                backdropFilter: 'blur(10px)',
                border: '1px solid rgba(255, 255, 255, 0.1)',
                boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
              }}
            >
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <MonetizationOn
                    sx={{ fontSize: 40, color: '#BB86FC', mr: 2 }}
                  />
                  <Typography variant="h4">
                    {statsData?.totalSales.toLocaleString('sv-SE', {
                      style: 'currency',
                      currency: 'SEK',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </Typography>
                </Box>
                <Typography variant="body2" color="text.secondary">
                  Total försäljning (inkl. moms)
                </Typography>
              </CardContent>
            </Card>
          </motion.div>
        </Grid>

        <Grid item xs={12} sm={6} md={2.4}>
          <Card
            sx={{
              height: '100%',
              background: 'linear-gradient(135deg, #03DAC620, #03DAC610)',
              transition: 'all 0.3s ease-in-out',
              '&:hover': {
                transform: 'scale(1.05)',
                background: 'linear-gradient(135deg, #03DAC630, #03DAC620)',
              },
            }}
          >
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <ShoppingCart sx={{ fontSize: 40, color: '#03DAC6', mr: 2 }} />
                <Typography variant="h4">{statsData?.totalOrders}</Typography>
              </Box>
              <Typography variant="body2" color="text.secondary">
                Antal ordrar
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={2.4}>
          <Card
            sx={{
              height: '100%',
              background: 'linear-gradient(135deg, #CF667920, #CF667910)',
              transition: 'all 0.3s ease-in-out',
              '&:hover': {
                transform: 'scale(1.05)',
                background: 'linear-gradient(135deg, #CF667930, #CF667920)',
              },
            }}
          >
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <ShowChart sx={{ fontSize: 40, color: '#CF6679', mr: 2 }} />
                <Typography variant="h4">
                  {statsData?.averageOrderValue.toLocaleString('sv-SE', {
                    style: 'currency',
                    currency: 'SEK',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </Typography>
              </Box>
              <Typography variant="body2" color="text.secondary">
                Snittorder (inkl. moms)
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={2.4}>
          <Card
            sx={{
              height: '100%',
              background: 'linear-gradient(135deg, #03DAC620, #CF667920)',
              transition: 'all 0.3s ease-in-out',
              '&:hover': {
                transform: 'scale(1.05)',
              },
            }}
          >
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <EmojiEvents sx={{ fontSize: 40, color: '#03DAC6', mr: 2 }} />
                <Typography variant="h4">
                  {statsData?.bestDay?.totalAmount
                    ? statsData.bestDay.totalAmount.toLocaleString('sv-SE', {
                        style: 'currency',
                        currency: 'SEK',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })
                    : '0 kr'}
                </Typography>
              </Box>
              <Typography variant="body2" color="text.secondary">
                Bästa dagen (
                {statsData?.bestDay?.saleDate
                  ? new Date(statsData.bestDay.saleDate).toLocaleDateString(
                      'sv-SE',
                      {
                        month: 'short',
                        day: 'numeric',
                      },
                    )
                  : '-'}
                )
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={2.4}>
          <Card
            sx={{
              height: '100%',
              background: 'linear-gradient(135deg, #BB86FC20, #03DAC620)',
              transition: 'all 0.3s ease-in-out',
              '&:hover': {
                transform: 'scale(1.05)',
              },
            }}
          >
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <BarChart sx={{ fontSize: 40, color: '#BB86FC', mr: 2 }} />
                <Typography variant="h4">
                  {extendedStats?.dailyAverage.toLocaleString('sv-SE', {
                    style: 'currency',
                    currency: 'SEK',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </Typography>
              </Box>
              <Typography variant="body2" color="text.secondary">
                Dagligt genomsnitt
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card sx={{ height: '400px' }}>
            <CardContent sx={{ p: 1, height: '100%' }}>
              <Typography variant="h6" gutterBottom>
                Försäljningsutveckling (inkl. moms)
              </Typography>
              <Box sx={{ height: 'calc(100% - 32px)' }}>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    data={chartData}
                    margin={{
                      top: 0,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="saleDate"
                      tickFormatter={(date) =>
                        new Date(date).toLocaleDateString('sv-SE', {
                          month: 'short',
                          day: 'numeric',
                        })
                      }
                    />
                    <YAxis
                      tickFormatter={(value) =>
                        value
                          .toLocaleString('sv-SE', {
                            style: 'currency',
                            currency: 'SEK',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })
                          .replace('kr', '')
                          .trim()
                      }
                    />
                    <Tooltip
                      content={(props) => (
                        <CustomTooltip {...props} salesType={salesType} />
                      )}
                    />
                    <Line
                      type="monotone"
                      dataKey="totalAmount"
                      stroke="url(#colorGradient)"
                      strokeWidth={3}
                      dot={{ r: 4, fill: '#BB86FC' }}
                      activeDot={{ r: 8, fill: '#03DAC6' }}
                    />
                    <defs>
                      <linearGradient
                        id="colorGradient"
                        x1="0"
                        y1="0"
                        x2="1"
                        y2="0"
                      >
                        <stop offset="0%" stopColor="#BB86FC" />
                        <stop offset="100%" stopColor="#03DAC6" />
                      </linearGradient>
                    </defs>
                  </LineChart>
                </ResponsiveContainer>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card sx={{ height: '400px' }}>
            <CardContent sx={{ p: 1, height: '100%' }}>
              <Typography variant="h6" gutterBottom>
                Försäljning per land (inkl. moms)
              </Typography>
              <Box
                sx={{
                  height: 'calc(100% - 32px)',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={pieChartData}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      label={({
                        cx,
                        cy,
                        midAngle,
                        innerRadius,
                        outerRadius,
                        percent,
                        name,
                        value,
                      }) => {
                        const RADIAN = Math.PI / 180;
                        const radius =
                          innerRadius + (outerRadius - innerRadius) * 0.5;
                        const x = cx + radius * Math.cos(-midAngle * RADIAN);
                        const y = cy + radius * Math.sin(-midAngle * RADIAN);

                        return (
                          <text
                            x={x}
                            y={y}
                            fill="white"
                            textAnchor={x > cx ? 'start' : 'end'}
                            dominantBaseline="central"
                          >
                            {`${name} ${(percent * 100).toFixed(0)}%`}
                          </text>
                        );
                      }}
                      outerRadius={100}
                      dataKey="value"
                    >
                      {pieChartData.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                    <Tooltip
                      formatter={(value: number) =>
                        value.toLocaleString('sv-SE', {
                          style: 'currency',
                          currency: 'SEK',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })
                      }
                    />
                  </PieChart>
                </ResponsiveContainer>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              mt: 3,
              mb: 3,
            }}
          >
            Försäljning per kategori
          </Typography>
          <Grid container spacing={3}>
            {categoryStats.map((category) => {
              const pieData = formatCategoryData(category);
              const totalValue = pieData.reduce(
                (sum, item) => sum + item.value,
                0,
              );

              return (
                <Grid item xs={12} md={4} key={category.mainCategoryId}>
                  <Card
                    sx={{
                      height: '400px',
                      background:
                        'linear-gradient(135deg, rgba(187, 134, 252, 0.1), rgba(3, 218, 198, 0.1))',
                      backdropFilter: 'blur(10px)',
                      transition: 'transform 0.3s ease-in-out',
                      '&:hover': {
                        transform: 'scale(1.02)',
                      },
                      '& .recharts-sector:focus': {
                        outline: 'none',
                      },
                    }}
                  >
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        {category.mainCategoryName}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ mb: 2 }}
                      >
                        Totalt:{' '}
                        {totalValue.toLocaleString('sv-SE', {
                          style: 'currency',
                          currency: 'SEK',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </Typography>
                      <Box sx={{ height: 300, position: 'relative' }}>
                        <ResponsiveContainer width="100%" height="100%">
                          <PieChart>
                            <Pie
                              data={pieData}
                              cx="50%"
                              cy="50%"
                              innerRadius={60}
                              outerRadius={80}
                              paddingAngle={2}
                              dataKey="value"
                              nameKey="name"
                              onClick={(data) => {
                                if (data && data.hasSubCategories) {
                                  fetchCategoryDetails(data.categoryId);
                                }
                              }}
                              label={({
                                cx,
                                cy,
                                midAngle,
                                innerRadius,
                                outerRadius,
                                percent,
                                value,
                                name,
                              }) => {
                                // Visa endast label för kategorier som utgör mer än 5% av totalen
                                if (percent < 0.05) return null;

                                const RADIAN = Math.PI / 180;
                                // Beräkna position för labeln
                                const radius = outerRadius + 20;
                                const x =
                                  cx + radius * Math.cos(-midAngle * RADIAN);
                                const y =
                                  cy + radius * Math.sin(-midAngle * RADIAN);

                                return (
                                  <text
                                    x={x}
                                    y={y}
                                    fill="#fff"
                                    textAnchor={x > cx ? 'start' : 'end'}
                                    dominantBaseline="central"
                                    style={{
                                      fontSize: '12px',
                                      fontWeight: 500,
                                      filter:
                                        'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.5))',
                                    }}
                                  >
                                    {name}
                                  </text>
                                );
                              }}
                              labelLine={true}
                            >
                              {pieData.map((entry, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={COLORS[index % COLORS.length]}
                                  style={{
                                    cursor: entry.hasSubCategories
                                      ? 'pointer'
                                      : 'default',
                                  }}
                                />
                              ))}
                            </Pie>
                            <Tooltip content={<CustomPieTooltip />} />
                            <Legend
                              verticalAlign="bottom"
                              height={36}
                              formatter={(value, entry) => {
                                const { payload } = entry as any;
                                const percentage = (
                                  (payload.value / totalValue) *
                                  100
                                ).toFixed(1);
                                return `${value} (${percentage}%)`;
                              }}
                            />
                          </PieChart>
                        </ResponsiveContainer>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  height: '400px',
                  background:
                    'linear-gradient(135deg, rgba(187, 134, 252, 0.1), rgba(3, 218, 198, 0.1))',
                }}
              >
                <CardContent sx={{ height: '100%', p: 3 }}>
                  <Typography variant="h6" gutterBottom>
                    Populäraste varumärken
                  </Typography>

                  {/* Sökfält och filtreringsknappar */}
                  <Box sx={{ mb: 2 }}>
                    <TextField
                      fullWidth
                      size="small"
                      value={brandSearch}
                      onChange={(e) => setBrandSearch(e.target.value)}
                      placeholder="Sök varumärke..."
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon sx={{ color: 'text.secondary' }} />
                          </InputAdornment>
                        ),
                        endAdornment: brandSearch && (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                e.preventDefault(); // Förhindra scroll
                                setBrandSearch('');
                              }}
                              sx={{ color: 'text.secondary' }}
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                        sx: {
                          backgroundColor: 'rgba(255, 255, 255, 0.05)',
                          '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.08)',
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'rgba(255, 255, 255, 0.1)',
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'rgba(187, 134, 252, 0.3)',
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#BB86FC',
                          },
                        },
                      }}
                    />

                    {/* Filtreringsknappar */}
                    <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
                      <Button
                        variant={
                          brandSortBy === 'revenue' ? 'contained' : 'outlined'
                        }
                        onClick={(e) => {
                          e.preventDefault(); // Förhindra scroll
                          setBrandSortBy('revenue');
                        }}
                        size="small"
                        sx={{
                          borderColor: 'rgba(187, 134, 252, 0.3)',
                          '&.MuiButton-contained': {
                            backgroundColor: '#BB86FC',
                            '&:hover': {
                              backgroundColor: '#9F66FF',
                            },
                          },
                        }}
                      >
                        Försäljningsvärde
                      </Button>
                      <Button
                        variant={
                          brandSortBy === 'units' ? 'contained' : 'outlined'
                        }
                        onClick={(e) => {
                          e.preventDefault(); // Förhindra scroll
                          setBrandSortBy('units');
                        }}
                        size="small"
                        sx={{
                          borderColor: 'rgba(187, 134, 252, 0.3)',
                          '&.MuiButton-contained': {
                            backgroundColor: '#BB86FC',
                            '&:hover': {
                              backgroundColor: '#9F66FF',
                            },
                          },
                        }}
                      >
                        Antal sålda
                      </Button>
                    </Box>
                  </Box>

                  {/* Lista med varumärken */}
                  <Box
                    sx={{
                      height: 'calc(100% - 110px)', // Justerad höjd för att ge plats åt sökfältet
                      overflowY: 'auto',
                      '&::-webkit-scrollbar': {
                        width: '8px',
                      },
                      '&::-webkit-scrollbar-track': {
                        background: 'rgba(255, 255, 255, 0.05)',
                        borderRadius: '4px',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        background: 'rgba(187, 134, 252, 0.3)',
                        borderRadius: '4px',
                        '&:hover': {
                          background: 'rgba(187, 134, 252, 0.5)',
                        },
                      },
                    }}
                  >
                    {filteredBrands?.length === 0 ? (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                          color: 'text.secondary',
                        }}
                      >
                        <Typography>Inga varumärken hittades</Typography>
                      </Box>
                    ) : (
                      filteredBrands?.map((brand, index) => (
                        <Box
                          key={`${brand.brandName}-${index}`}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mb: 2,
                            p: 2,
                            borderRadius: 1,
                            background: `linear-gradient(90deg, ${COLORS[index % COLORS.length]}20 0%, transparent 100%)`,
                            '&:hover': {
                              background: `linear-gradient(90deg, ${COLORS[index % COLORS.length]}40 0%, transparent 100%)`,
                            },
                          }}
                        >
                          <Box sx={{ flexGrow: 1 }}>
                            <Typography variant="subtitle1">
                              {index + 1}. {brand.brandName}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Antal sålda:{' '}
                              {brand.totalUnitsSold.toLocaleString('sv-SE')} st
                              {brand.sources && (
                                <Typography
                                  component="span"
                                  sx={{
                                    ml: 1,
                                    fontSize: '0.8rem',
                                    opacity: 0.7,
                                  }}
                                >
                                  ({brand.sources.join(', ')})
                                </Typography>
                              )}
                            </Typography>
                          </Box>
                          <Box sx={{ textAlign: 'right' }}>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                color: COLORS[index % COLORS.length],
                                fontWeight: 'bold',
                              }}
                            >
                              {Number(brand.totalRevenue).toLocaleString(
                                'sv-SE',
                                {
                                  style: 'currency',
                                  currency: 'SEK',
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                },
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      ))
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  height: '400px',
                  background:
                    'linear-gradient(135deg, rgba(3, 218, 198, 0.1), rgba(207, 102, 121, 0.1))',
                }}
              >
                <CardContent sx={{ height: '100%', p: 3 }}>
                  <Typography variant="h6" gutterBottom>
                    Populäraste produkter
                  </Typography>
                  <Box
                    sx={{
                      height: 'calc(100% - 32px)',
                      overflowY: 'auto',
                      '&::-webkit-scrollbar': {
                        width: '8px',
                      },
                      '&::-webkit-scrollbar-track': {
                        background: 'rgba(255, 255, 255, 0.05)',
                        borderRadius: '4px',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        background: 'rgba(3, 218, 198, 0.3)',
                        borderRadius: '4px',
                        '&:hover': {
                          background: 'rgba(3, 218, 198, 0.5)',
                        },
                      },
                    }}
                  >
                    {stats?.topProducts.map((product, index) => (
                      <Box
                        key={`${product.productName}-${product.brandName}-${index}`}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          mb: 2,
                          p: 2,
                          borderRadius: 1,
                          background: `linear-gradient(90deg, ${COLORS[index % COLORS.length]}20 0%, transparent 100%)`,
                          '&:hover': {
                            background: `linear-gradient(90deg, ${COLORS[index % COLORS.length]}40 0%, transparent 100%)`,
                          },
                        }}
                      >
                        <Box sx={{ flexGrow: 1 }}>
                          <Typography variant="subtitle1">
                            {index + 1}. {product.productName}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {product.brandName} • Antal sålda:{' '}
                            {product.totalUnitsSold.toLocaleString('sv-SE')} st
                            {product.sources && (
                              <Typography
                                component="span"
                                sx={{ ml: 1, fontSize: '0.8rem', opacity: 0.7 }}
                              >
                                ({product.sources.join(', ')})
                              </Typography>
                            )}
                          </Typography>
                        </Box>
                        <Box sx={{ textAlign: 'right' }}>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              color: COLORS[index % COLORS.length],
                              fontWeight: 'bold',
                            }}
                          >
                            {Number(product.totalRevenue).toLocaleString(
                              'sv-SE',
                              {
                                style: 'currency',
                                currency: 'SEK',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              },
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ mt: 3 }}>
          <Card sx={{ mb: 4 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Ordrar per dag och land
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  maxHeight: '500px',
                  overflowY: 'auto',
                }}
              >
                {/* Rubrikrad */}
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: '2fr 1fr 1fr 1fr',
                    gap: 2,
                    p: 2,
                    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                    position: 'sticky',
                    top: 0,
                    bgcolor: 'background.paper',
                    zIndex: 1,
                  }}
                >
                  <Typography variant="subtitle2">Datum</Typography>
                  <Typography variant="subtitle2">Sverige</Typography>
                  <Typography variant="subtitle2">Danmark</Typography>
                  <Typography variant="subtitle2">Totalt</Typography>
                </Box>

                {/* Data rader */}
                {stats &&
                  Object.values(
                    stats.dailyStats.reduce(
                      (acc: { [key: string]: any }, curr) => {
                        const date = curr.saleDate;
                        if (!acc[date]) {
                          acc[date] = {
                            date,
                            SE: { orders: 0, amount: 0 },
                            DK: { orders: 0, amount: 0 },
                          };
                        }
                        const country =
                          curr.currencyCode === 'SEK' ? 'SE' : 'DK';
                        acc[date][country].orders += Number(curr.orderCount);
                        acc[date][country].amount += Number(curr.totalAmount);
                        return acc;
                      },
                      {} as { [key: string]: any },
                    ),
                  )
                    .sort(
                      (a: any, b: any) =>
                        new Date(b.date).getTime() - new Date(a.date).getTime(),
                    )
                    .map((dayData: any) => (
                      <Box
                        key={dayData.date}
                        sx={{
                          display: 'grid',
                          gridTemplateColumns: '2fr 1fr 1fr 1fr',
                          gap: 2,
                          p: 2,
                          borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
                          '&:hover': {
                            bgcolor: 'rgba(187, 134, 252, 0.1)',
                          },
                        }}
                      >
                        <Typography>
                          {new Date(dayData.date).toLocaleDateString('sv-SE', {
                            weekday: 'long',
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                          })}
                        </Typography>

                        <Box>
                          <Typography sx={{ color: '#03DAC6' }}>
                            {Math.round(dayData.SE.orders)} ordrar
                          </Typography>
                          <Typography variant="body2" sx={{ color: '#BB86FC' }}>
                            {dayData.SE.amount.toLocaleString('sv-SE', {
                              style: 'currency',
                              currency: 'SEK',
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            })}
                          </Typography>
                        </Box>

                        <Box>
                          <Typography sx={{ color: '#03DAC6' }}>
                            {Math.round(dayData.DK.orders)} ordrar
                          </Typography>
                          <Typography variant="body2" sx={{ color: '#BB86FC' }}>
                            {dayData.DK.amount.toLocaleString('sv-SE', {
                              style: 'currency',
                              currency: 'SEK',
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            })}
                          </Typography>
                        </Box>

                        <Box>
                          <Typography sx={{ color: '#03DAC6' }}>
                            {Math.round(dayData.SE.orders + dayData.DK.orders)}{' '}
                            ordrar
                          </Typography>
                          <Typography variant="body2" sx={{ color: '#BB86FC' }}>
                            {(
                              dayData.SE.amount + dayData.DK.amount
                            ).toLocaleString('sv-SE', {
                              style: 'currency',
                              currency: 'SEK',
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            })}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Dialog
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Underkategorier</DialogTitle>
          <DialogContent>
            {categoryDetails.length > 0 && (
              <Box
                sx={{
                  height: 400,
                  position: 'relative',
                  '& .recharts-sector:focus': {
                    outline: 'none',
                  },
                }}
              >
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={categoryDetails.map((detail) => ({
                        name: detail.subCategoryName,
                        value: parseFloat(detail.totalRevenue),
                      }))}
                      cx="50%"
                      cy="50%"
                      innerRadius={60}
                      outerRadius={80}
                      paddingAngle={2}
                      dataKey="value"
                      nameKey="name"
                      label={({
                        cx,
                        cy,
                        midAngle,
                        innerRadius,
                        outerRadius,
                        percent,
                        value,
                        name,
                      }) => {
                        // Visa endast label för kategorier som utgör mer än 5% av totalen
                        if (percent < 0.05) return null;

                        const RADIAN = Math.PI / 180;
                        // Beräkna position för labeln
                        const radius = outerRadius + 20;
                        const x = cx + radius * Math.cos(-midAngle * RADIAN);
                        const y = cy + radius * Math.sin(-midAngle * RADIAN);

                        return (
                          <text
                            x={x}
                            y={y}
                            fill="#fff"
                            textAnchor={x > cx ? 'start' : 'end'}
                            dominantBaseline="central"
                            style={{
                              fontSize: '12px',
                              fontWeight: 500,
                              filter:
                                'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.5))',
                            }}
                          >
                            {name}
                          </text>
                        );
                      }}
                      labelLine={true}
                    >
                      {categoryDetails.map((_, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                    <Tooltip content={<CustomPieTooltip />} />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsDialogOpen(false)}>Stäng</Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Container>
  );
};

export default SalesDashboard;
