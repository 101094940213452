import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  Typography,
  Box,
  IconButton,
  Card,
  CardContent,
  Button,
  Grow,
  Divider,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InventoryIcon from '@mui/icons-material/Inventory';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { StockNotification } from '../types/notifications';

interface StockNotificationsDialogProps {
  open: boolean;
  onClose: () => void;
  notifications: StockNotification[];
  onMarkAsRead: (notificationId: number) => void;
}

const StockNotificationsDialog: React.FC<StockNotificationsDialogProps> = ({
  open,
  onClose,
  notifications,
  onMarkAsRead,
}) => {
  const theme = useTheme();
  const [displayCount, setDisplayCount] = useState(5);

  // Begränsa antalet notifikationer som visas
  const visibleNotifications = notifications.slice(0, displayCount);

  // Hantera "Visa mer"
  const handleLoadMore = () => {
    setDisplayCount((prev) => prev + 5);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 3,
          background: 'rgba(30, 41, 59, 0.95)',
          backdropFilter: 'blur(20px)',
          border: '1px solid rgba(255, 255, 255, 0.1)',
        },
      }}
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 3,
          display: 'flex',
          alignItems: 'center',
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
          <InventoryIcon
            sx={{
              mr: 2,
              color: theme.palette.primary.main,
              fontSize: 28,
            }}
          />
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            Lagerförändringar
          </Typography>
        </Box>
        <IconButton
          aria-label="stäng"
          onClick={onClose}
          sx={{
            color: 'grey.500',
            transition: 'all 0.2s',
            '&:hover': {
              color: 'grey.300',
              transform: 'rotate(90deg)',
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 3 }}>
        {notifications.length === 0 ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              p: 6,
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <InventoryIcon sx={{ fontSize: 48, color: 'grey.600' }} />
            <Typography variant="h6" color="text.secondary">
              Inga nya lagerförändringar
            </Typography>
          </Box>
        ) : (
          <>
            <List sx={{ p: 0 }}>
              {visibleNotifications.map((notification, index) => (
                <Grow
                  key={notification.notificationId}
                  in={true}
                  timeout={(index + 1) * 200}
                >
                  <Card
                    sx={{
                      mb: 2,
                      background: 'rgba(255, 255, 255, 0.05)',
                      transition: 'all 0.3s ease',
                      border: '1px solid rgba(255, 255, 255, 0.1)',
                      borderRadius: 2,
                    }}
                  >
                    <CardContent sx={{ position: 'relative', p: 3 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box>
                          <Typography
                            variant="h6"
                            gutterBottom
                            sx={{ fontWeight: 600 }}
                          >
                            {notification.brandName} -{' '}
                            {notification.productName}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              color: 'grey.400',
                              mb: 2,
                              display: 'flex',
                              alignItems: 'center',
                              gap: 0.5,
                            }}
                          >
                            Art.nr: {notification.productNumber}
                          </Typography>
                        </Box>
                        <Button
                          variant="contained"
                          size="small"
                          startIcon={<CheckCircleIcon />}
                          onClick={() =>
                            onMarkAsRead(notification.notificationId)
                          }
                          sx={{
                            borderRadius: '20px',
                            background: 'rgba(139, 92, 246, 0.1)',
                            backdropFilter: 'blur(10px)',
                            border: '1px solid rgba(139, 92, 246, 0.2)',
                            color: theme.palette.primary.main,
                            '&:hover': {
                              background: 'rgba(139, 92, 246, 0.2)',
                            },
                          }}
                        >
                          Markera som läst
                        </Button>
                      </Box>

                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 3,
                          background: 'rgba(0, 0, 0, 0.2)',
                          borderRadius: 2,
                          p: 2,
                        }}
                      >
                        <Box
                          sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                        >
                          <Typography variant="body2" color="text.secondary">
                            Tidigare:
                          </Typography>
                          <Typography variant="h6">
                            {notification.previousBalance} st
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color: notification.increase
                              ? 'success.main'
                              : 'error.main',
                            gap: 1,
                          }}
                        >
                          {notification.increase ? (
                            <TrendingUpIcon />
                          ) : (
                            <TrendingDownIcon />
                          )}
                          <Typography variant="h6">
                            {notification.increase ? '+' : ''}
                            {notification.newBalance -
                              notification.previousBalance}{' '}
                            st
                          </Typography>
                        </Box>

                        <Box
                          sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                        >
                          <Typography variant="body2" color="primary.main">
                            Nytt saldo:
                          </Typography>
                          <Typography variant="h6" color="primary.main">
                            {notification.newBalance} st
                          </Typography>
                        </Box>
                      </Box>

                      <Typography
                        variant="caption"
                        sx={{
                          display: 'block',
                          mt: 2,
                          color: 'grey.500',
                          fontStyle: 'italic',
                        }}
                      >
                        {new Date(notification.createdAt).toLocaleDateString(
                          'sv-SE',
                          {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                          },
                        )}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grow>
              ))}
            </List>
            {displayCount < notifications.length && (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Button
                  onClick={handleLoadMore}
                  variant="outlined"
                  sx={{
                    borderColor: 'rgba(139, 92, 246, 0.2)',
                    color: theme.palette.primary.main,
                    '&:hover': {
                      borderColor: theme.palette.primary.main,
                      background: 'rgba(139, 92, 246, 0.1)',
                    },
                  }}
                >
                  Visa fler ({notifications.length - displayCount} kvar)
                </Button>
              </Box>
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default StockNotificationsDialog;
