// DropzoneArea.tsx
import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FormControlLabel, Checkbox, Typography } from '@mui/material';
import { createImagePreviewUrl } from '../utils/imageUtils';

interface DropzoneAreaProps {
  onDrop: (files: File[]) => void;
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  transparentIndices: number[];
  setTransparentIndices: React.Dispatch<React.SetStateAction<number[]>>;
}

const DropzoneArea: React.FC<DropzoneAreaProps> = ({
  onDrop,
  files,
  setFiles,
  transparentIndices,
  setTransparentIndices,
}) => {
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = files.findIndex((file) => file.name === active.id);
      const newIndex = files.findIndex((file) => file.name === over.id);

      if (oldIndex !== -1 && newIndex !== -1) {
        const newFilesOrder = arrayMove(files, oldIndex, newIndex);
        setFiles(newFilesOrder);
      }
    }
  };

  const handleTransparentChange = (index: number) => {
    setTransparentIndices((prev) => {
      if (prev.includes(index)) {
        return prev.filter((i) => i !== index);
      } else {
        return [...prev, index];
      }
    });
  };

  const dropzoneStyle: React.CSSProperties = {
    border: '1px solid rgba(248, 250, 252, 0.08)',
    borderRadius: '1.25rem',
    padding: '2rem',
    background: 'rgba(30, 41, 59, 0.7)',
    backdropFilter: 'blur(20px)',
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',
    marginBottom: '0.75rem',
  };

  return (
    <div>
      <div
        {...getRootProps()}
        style={dropzoneStyle}
        onMouseOver={(e) => {
          const target = e.currentTarget;
          target.style.borderColor = '#8B5CF6';
          target.style.background = 'rgba(139, 92, 246, 0.1)';
        }}
        onMouseOut={(e) => {
          const target = e.currentTarget;
          target.style.borderColor = 'rgba(248, 250, 252, 0.08)';
          target.style.background = 'rgba(30, 41, 59, 0.7)';
        }}
      >
        <input {...getInputProps()} />
        <p
          style={{
            color: 'rgba(248, 250, 252, 0.7)',
            textAlign: 'center',
            margin: 0,
            marginBottom: '0px',
          }}
        >
          Dra och släpp filer här, eller klicka för att välja filer
        </p>
      </div>

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={files.map((file) => file.name)}
          strategy={verticalListSortingStrategy}
        >
          {files.map((file, index) => (
            <SortableItem
              key={file.name}
              id={file.name}
              file={file}
              index={index}
              transparentIndices={transparentIndices}
              onTransparentChange={handleTransparentChange}
            />
          ))}
        </SortableContext>
      </DndContext>
    </div>
  );
};

interface SortableItemProps {
  id: string;
  file: File;
  index: number;
  transparentIndices: number[];
  onTransparentChange: (index: number) => void;
}

const SortableItem: React.FC<SortableItemProps> = ({
  id,
  file,
  index,
  transparentIndices,
  onTransparentChange,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const [transformOrigin, setTransformOrigin] = useState({
    x: '50%',
    y: '50%',
  });

  const [isHovered, setIsHovered] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string>('');

  useEffect(() => {
    const loadPreview = async () => {
      try {
        const url = await createImagePreviewUrl(file);
        setPreviewUrl(url);
      } catch (error) {
        console.error('Error creating preview:', error);
        setPreviewUrl('/placeholder-image.png');
      }
    };
    loadPreview();

    return () => {
      if (previewUrl && !previewUrl.startsWith('data:')) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [file]);

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    padding: '1rem',
    border: '1px solid rgba(248, 250, 252, 0.08)',
    borderRadius: '0.75rem',
    marginBottom: '0.75rem',
    background: isHovered ? 'rgba(139, 92, 246, 0.1)' : 'rgba(30, 41, 59, 0.7)',
    backdropFilter: 'blur(20px)',
    cursor: 'move',
    display: 'flex',
    alignItems: 'center',
    color: '#F8FAFC',
    borderColor: isHovered ? '#8B5CF6' : 'rgba(248, 250, 252, 0.08)',
    boxShadow: isHovered ? '0 4px 6px -1px rgba(139, 92, 246, 0.1)' : 'none',
  };

  const imageStyle: React.CSSProperties = {
    width: '50px',
    height: '50px',
    marginRight: '1rem',
    objectFit: 'contain',
    transition: 'transform 0.2s ease-in-out',
    transform: 'scale(1)',
    transformOrigin: `${transformOrigin.x} ${transformOrigin.y}`,
    borderRadius: '0.5rem',
    background: 'rgba(15, 23, 42, 0.3)',
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLImageElement>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;
    setTransformOrigin({ x: `${x}%`, y: `${y}%` });
    e.currentTarget.style.transform = 'scale(1.5)';
  };

  const handleMouseLeave = (e: React.MouseEvent<HTMLImageElement>) => {
    e.currentTarget.style.transform = 'scale(1)';
  };

  const truncateFileName = (fileName: string, maxLength = 20) => {
    if (fileName.length <= maxLength) return fileName;
    const extension = fileName.split('.').pop();
    const nameWithoutExt = fileName.substring(0, fileName.lastIndexOf('.'));
    const truncatedName = nameWithoutExt.substring(0, maxLength - 3) + '...';
    return extension ? `${truncatedName}.${extension}` : truncatedName;
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
          width: '100%',
        }}
      >
        <div
          style={{
            position: 'relative',
            overflow: 'hidden',
            borderRadius: '0.5rem',
            background: 'rgba(15, 23, 42, 0.3)',
          }}
        >
          <img
            src={previewUrl}
            alt={file.name}
            style={imageStyle}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
          />
        </div>
        <p
          style={{
            maxWidth: '200px',
            overflow: 'hidden',
            margin: 0,
            color: 'rgba(248, 250, 252, 0.9)',
            fontWeight: 500,
          }}
        >
          {truncateFileName(file.name)}
        </p>
        <FormControlLabel
          control={
            <Checkbox
              checked={transparentIndices.includes(index)}
              onChange={() => onTransparentChange(index)}
              onClick={(e) => e.stopPropagation()}
              disabled={true}
              sx={{
                color: 'rgba(248, 250, 252, 0.5)',
                '&.Mui-checked': {
                  color: '#8B5CF6',
                },
                '&:hover': {
                  backgroundColor: 'rgba(139, 92, 246, 0.1)',
                },
                '&.Mui-disabled': {
                  color: 'rgba(248, 250, 252, 0.3)',
                },
              }}
            />
          }
          label={
            <Typography
              sx={{
                color: 'rgba(248, 250, 252, 0.4)',
                fontSize: '0.875rem',
                fontWeight: 500,
              }}
            >
              Transparent bakgrund
            </Typography>
          }
          sx={{ marginLeft: 'auto' }}
        />
      </div>
    </div>
  );
};

export default DropzoneArea;
