import React, { useState, useEffect } from 'react';
import {
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  TablePagination,
  Checkbox,
  FormControlLabel,
  Grid,
  Collapse,
  Snackbar,
  Alert,
} from '@mui/material';
import config from '../config';
import FilterListIcon from '@mui/icons-material/FilterList';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';

// Lägg till denna styled-komponent före PriceChangeModule
const ExpandMore = styled((props: any) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

// Definiera gränssnitt för produkter och SKU-priser
interface Product {
  productId: number;
  productNumber: string;
  productName: string;
}

interface SKUPrice {
  skuId: number;
  priceLookupUnitNumber: string;
  attribute1Code: string;
  unitPrice: number; // Aktuellt försäljningspris
  unitPriceOriginal: number; // Ordinarie pris
  taxRate: number;
}

interface CampaignSuggestion {
  productId: number;
  productNumber: string;
  productName: string;
  brandName: string;
  attribute1Code: string;
  attribute1Number: string;
  currentStock: number;
  daysSinceLastUpdate: number;
  currentPrice: number;
  suggestedDiscount: number;
  suggestedPrice: number;
  currencyCode: string;
}

// Lägg till interface för pagination
interface PaginationData {
  currentPage: number;
  totalPages: number;
  totalItems: number;
  itemsPerPage: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

// Huvudkomponenten för prisändring
const PriceChangeModule: React.FC = () => {
  // States för produkter, sökning, vald produkt, paginering och dialog
  const [products, setProducts] = useState<Product[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedProduct, setSelectedProduct] = useState<
    (Product & { skus: SKUPrice[] }) | null
  >(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Ny state för att hantera prisinmatningar som strängar
  const [priceInputs, setPriceInputs] = useState<{
    [skuId: number]: { unitPrice: string; unitPriceOriginal: string };
  }>({});

  const [isLoading, setIsLoading] = useState(false);

  // Lägg till ny state-variabel
  const [noPriceFilter, setNoPriceFilter] = useState(false);

  // Lägg till denna rad bland de andra state-deklarationerna
  const [expanded, setExpanded] = useState(false);

  // Lägg till dessa states
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success',
  );

  const [showCampaignSuggestions, setShowCampaignSuggestions] = useState(false);
  const [campaignSuggestions, setCampaignSuggestions] = useState<
    CampaignSuggestion[]
  >([]);

  // Lägg till nya states för paginering och sortering
  const [campaignPage, setCampaignPage] = useState(0);
  const [campaignRowsPerPage, setCampaignRowsPerPage] = useState(10);
  const [campaignPagination, setCampaignPagination] =
    useState<PaginationData | null>(null);
  const [sortBy, setSortBy] = useState('daysSinceLastUpdate');
  const [sortOrder, setSortOrder] = useState<'ASC' | 'DESC'>('DESC');

  // Lägg till nytt state för rabattinput
  const [discountInput, setDiscountInput] = useState<{
    [skuId: number]: string;
  }>({});

  // Lägg till ny state-variabel för att spåra sparandet
  const [isSaving, setIsSaving] = useState(false);

  // Lägg till ny state för kampanjsökning
  const [campaignSearchTerm, setCampaignSearchTerm] = useState('');

  const [skuPrices, setSkuPrices] = useState<SKUPrice[]>([]);

  // Lägg till funktion för att beräkna rabattprocent
  const calculateDiscountPercentage = (
    originalPrice: number,
    currentPrice: number,
  ): string => {
    if (originalPrice <= 0 || currentPrice >= originalPrice) return '';
    const discount = ((originalPrice - currentPrice) / originalPrice) * 100;
    return discount.toFixed(0);
  };

  // Uppdatera useEffect för att sätta initiala priser och rabatter
  useEffect(() => {
    if (selectedProduct) {
      const initialInputs: {
        [skuId: number]: { unitPrice: string; unitPriceOriginal: string };
      } = {};
      const initialDiscountInputs: { [skuId: number]: string } = {};

      selectedProduct.skus.forEach((sku) => {
        initialInputs[sku.skuId] = {
          unitPrice: sku.unitPrice.toString().replace('.', ','),
          unitPriceOriginal: sku.unitPriceOriginal.toString().replace('.', ','),
        };

        // Beräkna och sätt initial rabattprocent
        const discountPercentage = calculateDiscountPercentage(
          sku.unitPriceOriginal,
          sku.unitPrice,
        );
        if (discountPercentage) {
          initialDiscountInputs[sku.skuId] = discountPercentage;
        }
      });
      setPriceInputs(initialInputs);
      setDiscountInput(initialDiscountInputs);
    } else {
      setPriceInputs({});
      setDiscountInput({});
    }
  }, [selectedProduct]);

  // Funktion för att hämta produkter från API
  const fetchProducts = async (
    search: string,
    page: number,
    pageSize: number,
  ) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${config.API_URL}/products?search=${encodeURIComponent(
          search,
        )}&page=${page + 1}&pageSize=${pageSize}&noPriceFilter=${noPriceFilter}`,
      );

      if (!response.ok) {
        console.error(
          'Server responded with an error:',
          response.status,
          response.statusText,
        );
        return;
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Funktion för att hämta SKUs för en specifik produkt från API
  const fetchProductSKUs = async (productId: number) => {
    try {
      const response = await fetch(
        `${config.API_URL}/products/${productId}/skus`,
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error(
          'Server responded with an error:',
          response.status,
          response.statusText,
          errorText,
        );
        return;
      }

      const data: SKUPrice[] = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching SKUs:', error);
    }
  };

  // Ladda produkter när sökterm eller sida ändras
  useEffect(() => {
    const loadProducts = async () => {
      const data = await fetchProducts(searchTerm, page, rowsPerPage);
      if (data) {
        setProducts(data.products);
        setTotalPages(data.totalPages);
      }
    };
    loadProducts();
  }, [searchTerm, page, rowsPerPage, noPriceFilter]);

  // Hantera klick på en produkt för att hämta och visa SKUs
  const handleProductClick = async (product: Product) => {
    const skuData = await fetchProductSKUs(product.productId);
    if (skuData) {
      setSelectedProduct({ ...product, skus: skuData });
      setIsDialogOpen(true);
    }
  };

  // Hantera sökinput
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPage(0);
    setSelectedProduct(null);
  };

  // Hantera stängning av dialog
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedProduct(null);
    setPriceInputs({});
    setDiscountInput({});
  };

  // Hantera prisändring genom att uppdatera priceInputs state
  const handlePriceChange = (
    skuId: number,
    priceType: 'unitPrice' | 'unitPriceOriginal',
    value: string,
  ) => {
    setPriceInputs((prev) => ({
      ...prev,
      [skuId]: {
        ...prev[skuId],
        [priceType]: value,
      },
    }));
  };

  // Hantera sparande av priser genom att konvertera strängar till nummer och skicka till API
  const handleSavePrices = async () => {
    if (!selectedProduct) return;

    setIsSaving(true); // Sätt laddning till true när vi börjar spara

    try {
      const updatedSkus = selectedProduct.skus.map((sku) => {
        const input = priceInputs[sku.skuId];
        return {
          ...sku,
          unitPriceOriginal:
            parseFloat(input?.unitPriceOriginal.replace(',', '.')) ||
            sku.unitPriceOriginal,
          unitPrice:
            parseFloat(input?.unitPrice.replace(',', '.')) || sku.unitPrice,
        };
      });

      const response = await fetch(
        `${config.API_URL}/products/${selectedProduct.productId}/update-prices`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ skus: updatedSkus }),
        },
      );

      const data = await response.json();

      if (response.ok) {
        setSnackbarMessage('Priser uppdaterade');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);

        handleCloseDialog();

        const updatedData = await fetchProducts(searchTerm, page, rowsPerPage);
        if (updatedData) {
          setProducts(updatedData.products);
          setTotalPages(updatedData.totalPages);
        }
      } else {
        alert(`Fel vid uppdatering av priser: ${data.error}`);
      }
    } catch (error) {
      console.error('Error updating prices:', error);
      alert('Ett fel inträffade vid uppdatering av priser');
    } finally {
      setIsSaving(false); // Återställ laddning oavsett resultat
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Lägg till hanterare för filtret
  const handleNoPriceFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNoPriceFilter(e.target.checked);
    setPage(0);
  };

  // Lägg till denna hanterare
  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  // Separera laddningsfunktionen för kampanjförslag
  const loadCampaignSuggestions = async () => {
    try {
      const params: any = {
        page: campaignPage + 1,
        limit: campaignRowsPerPage,
        minDays: 15,
        minStock: 5,
        sortBy,
        sortOrder,
      };

      // Lägg endast till search-parametern om det faktiskt finns en söksträng
      if (campaignSearchTerm.trim()) {
        params.search = campaignSearchTerm;
      }

      const response = await axios.get<{
        campaignSuggestions: CampaignSuggestion[];
        pagination: PaginationData;
      }>(`${config.API_URL}/sales/campaign-suggestions`, {
        params,
      });

      setCampaignSuggestions(response.data.campaignSuggestions);
      setCampaignPagination(response.data.pagination);
    } catch (error) {
      console.error('Fel vid hämtning av kampanjförslag:', error);
    }
  };

  // Uppdatera useEffect för kampanjförslag
  useEffect(() => {
    if (showCampaignSuggestions) {
      loadCampaignSuggestions();
    }
  }, [
    showCampaignSuggestions,
    campaignPage,
    campaignRowsPerPage,
    sortBy,
    sortOrder,
    campaignSearchTerm,
  ]);

  // Ta bort den direkta anropet av loadCampaignSuggestions från handleCampaignSearchChange
  const handleCampaignSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCampaignSearchTerm(event.target.value);
    setCampaignPage(0); // Återställ till första sidan vid ny sökning
  };

  // Hanterare för paginering
  const handleCampaignPageChange = (event: unknown, newPage: number) => {
    setCampaignPage(newPage);
  };

  const handleCampaignRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCampaignRowsPerPage(parseInt(event.target.value, 10));
    setCampaignPage(0);
  };

  const handleDiscountChange = (skuId: number, discountValue: string) => {
    // Uppdatera discount input state
    setDiscountInput((prev) => ({
      ...prev,
      [skuId]: discountValue,
    }));

    // Om värdet är tomt, sätt försäljningspris till ordinarie pris
    if (!discountValue.trim()) {
      const originalPrice = priceInputs[skuId]?.unitPriceOriginal || '0';
      handlePriceChange(skuId, 'unitPrice', originalPrice);
      return;
    }

    // Hämta originalpriser
    const originalPrice =
      parseFloat(priceInputs[skuId]?.unitPriceOriginal.replace(',', '.')) || 0;

    if (originalPrice && discountValue) {
      const discount = parseFloat(discountValue) / 100;
      if (!isNaN(discount)) {
        // Avrunda till heltal med Math.round
        const discountedPrice = Math.round(originalPrice * (1 - discount));
        // Uppdatera försäljningspriset
        handlePriceChange(skuId, 'unitPrice', discountedPrice.toString());
      }
    }
  };

  return (
    <Box p={2}>
      <Typography variant="h4" gutterBottom>
        Prishantering
      </Typography>

      <Button
        variant="contained"
        color="primary"
        onClick={() => setShowCampaignSuggestions(!showCampaignSuggestions)}
        sx={{ mr: 2 }}
      >
        {showCampaignSuggestions
          ? 'Visa alla produkter'
          : 'Visa kampanjförslag'}
      </Button>

      {/* Visa rätt sökruta beroende på läge */}
      {showCampaignSuggestions ? (
        <TextField
          label="Sök kampanjförslag..."
          variant="outlined"
          fullWidth
          value={campaignSearchTerm}
          onChange={handleCampaignSearchChange}
          margin="normal"
        />
      ) : (
        <TextField
          label="Sök artikelnummer eller produktnamn..."
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={handleSearchChange}
          margin="normal"
        />
      )}

      <Paper sx={{ p: 2, mb: 2 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => setExpanded(!expanded)}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FilterListIcon sx={{ mr: 1 }} />
            <Typography variant="h6">Produktfilter</Typography>
          </Box>
          <ExpandMore
            expand={expanded}
            aria-expanded={expanded}
            aria-label="visa filter"
            sx={{ ml: 'auto' }}
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Box>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 2,
                    backgroundColor: 'rgba(0, 0, 0, 0.02)',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <MonetizationOnIcon sx={{ mr: 1, color: 'primary.main' }} />
                    <Typography variant="subtitle1">Pris</Typography>
                  </Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={noPriceFilter}
                        onChange={handleNoPriceFilterChange}
                      />
                    }
                    label="Saknar pris"
                  />
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Collapse>
      </Paper>

      {showCampaignSuggestions ? (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Artikelnummer</TableCell>
                  <TableCell>Produkt</TableCell>
                  <TableCell>Varumärke</TableCell>
                  <TableCell>Färg</TableCell>
                  <TableCell
                    align="right"
                    onClick={() => {
                      setSortBy('currentStock');
                      setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC');
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    Lagersaldo
                  </TableCell>
                  <TableCell
                    align="right"
                    onClick={() => {
                      setSortBy('daysSinceLastUpdate');
                      setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC');
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    Dagar i lager
                  </TableCell>
                  <TableCell
                    align="right"
                    onClick={() => {
                      setSortBy('currentPrice');
                      setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC');
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    Nuvarande pris
                  </TableCell>
                  <TableCell align="right">Föreslagen rabatt</TableCell>
                  <TableCell align="right">Föreslaget pris</TableCell>
                  <TableCell align="right">Åtgärder</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {campaignSuggestions.map((suggestion, index) => (
                  <TableRow
                    key={`${suggestion.productId}-${suggestion.attribute1Number}-${suggestion.attribute1Code}-${index}`}
                  >
                    <TableCell>{suggestion.productNumber}</TableCell>
                    <TableCell>{suggestion.productName}</TableCell>
                    <TableCell>{suggestion.brandName}</TableCell>
                    <TableCell>{suggestion.attribute1Code}</TableCell>
                    <TableCell align="right">
                      {suggestion.currentStock}
                    </TableCell>
                    <TableCell align="right">
                      {suggestion.daysSinceLastUpdate}
                    </TableCell>
                    <TableCell align="right">
                      {suggestion.currentPrice.toLocaleString('sv-SE', {
                        style: 'currency',
                        currency: suggestion.currencyCode,
                      })}
                    </TableCell>
                    <TableCell align="right">
                      {(suggestion.suggestedDiscount * 100).toFixed(0)}%
                    </TableCell>
                    <TableCell align="right">
                      {suggestion.suggestedPrice.toLocaleString('sv-SE', {
                        style: 'currency',
                        currency: suggestion.currencyCode,
                      })}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() =>
                          handleProductClick({
                            productId: suggestion.productId,
                            productNumber: suggestion.productNumber,
                            productName: suggestion.productName,
                            // ... andra nödvändiga fält
                          })
                        }
                      >
                        Ändra pris
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={campaignPagination?.totalItems || 0}
            page={campaignPage}
            onPageChange={handleCampaignPageChange}
            rowsPerPage={campaignRowsPerPage}
            onRowsPerPageChange={handleCampaignRowsPerPageChange}
            labelRowsPerPage="Rader per sida:"
            labelDisplayedRows={({ from, to, count }) => {
              if (count === 0) return 'Inga resultat';
              return `${from}-${to} av ${count !== -1 ? count : `mer än ${to}`}`;
            }}
          />
        </>
      ) : (
        <>
          <TableContainer component={Paper} sx={{ position: 'relative' }}>
            {isLoading && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'rgba(18, 18, 18, 0.7)',
                  backdropFilter: 'blur(4px)',
                  zIndex: 1,
                }}
              >
                <CircularProgress sx={{ color: '#BB86FC' }} />
              </Box>
            )}
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Artikelnummer</TableCell>
                  <TableCell>Produktnamn</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map((product) => (
                  <TableRow
                    key={product.productId}
                    hover
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleProductClick(product)}
                  >
                    <TableCell>{product.productNumber}</TableCell>
                    <TableCell>{product.productName}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalPages * rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Rader per sida:"
          />
        </>
      )}

      {/* Dialog för vald produkt */}
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          {selectedProduct?.productNumber} - {selectedProduct?.productName}
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Färg</TableCell>
                  <TableCell>Ordinarie Pris</TableCell>
                  <TableCell>Rabatt %</TableCell>
                  <TableCell>Försäljningspris</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedProduct?.skus.map((sku) => (
                  <TableRow key={sku.skuId}>
                    <TableCell>{sku.attribute1Code}</TableCell>
                    <TableCell>
                      <TextField
                        value={priceInputs[sku.skuId]?.unitPriceOriginal || ''}
                        variant="outlined"
                        size="small"
                        label="SEK"
                        onChange={(e) =>
                          handlePriceChange(
                            sku.skuId,
                            'unitPriceOriginal',
                            e.target.value,
                          )
                        }
                        inputProps={{
                          inputMode: 'decimal',
                          pattern: '^[0-9]*[.,]?[0-9]*$',
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={discountInput[sku.skuId] || ''}
                        variant="outlined"
                        size="small"
                        label="%"
                        onChange={(e) =>
                          handleDiscountChange(sku.skuId, e.target.value)
                        }
                        inputProps={{
                          inputMode: 'decimal',
                          pattern: '^[0-9]*[.,]?[0-9]*$',
                          style: { width: '80px' },
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={priceInputs[sku.skuId]?.unitPrice || ''}
                        variant="outlined"
                        size="small"
                        label="SEK"
                        onChange={(e) =>
                          handlePriceChange(
                            sku.skuId,
                            'unitPrice',
                            e.target.value,
                          )
                        }
                        inputProps={{
                          inputMode: 'decimal',
                          pattern: '^[0-9]*[.,]?[0-9]*$',
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            color="primary"
            disabled={isSaving}
          >
            Stäng
          </Button>
          <Button
            onClick={handleSavePrices}
            color="primary"
            variant="contained"
            disabled={isSaving}
            startIcon={isSaving ? <CircularProgress size={20} /> : null}
          >
            {isSaving ? 'Sparar...' : 'Spara'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Lägg till Snackbar i slutet av komponenten */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{
            width: '100%',
            backgroundColor: (theme) =>
              snackbarSeverity === 'success'
                ? theme.palette.success.main
                : theme.palette.error.main,
            color: 'white',
            '& .MuiAlert-icon': {
              color: 'white',
            },
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default PriceChangeModule;
