// UploadMediaModal.tsx
import React, { useState } from 'react';
import {
  Modal,
  TextField,
  Button,
  Typography,
  Autocomplete,
  CircularProgress,
  Box,
} from '@mui/material';
import DropzoneArea from './DropzoneArea';

interface UploadMediaModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: UploadData) => void;
  uploadedImages: string[];
  handlePreview: (data: UploadData) => void;
  productNumber: string;
  availableColors: string[];
  initialColorCode: string;
  attribute1Number: string; // Ny prop
}

interface UploadData {
  articleNumber: string;
  colorCode: string;
  attribute1Number: string;
  images: File[];
  transparentIndices: number[];
}

const UploadMediaModal: React.FC<UploadMediaModalProps> = ({
  open,
  onClose,
  onSubmit,
  uploadedImages,
  handlePreview,
  productNumber,
  availableColors,
  initialColorCode,
  attribute1Number, // Ny prop
}) => {
  const [selectedColor, setSelectedColor] = useState<string>(
    initialColorCode || '',
  );
  const [files, setFiles] = useState<File[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [transparentIndices, setTransparentIndices] = useState<number[]>([]);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  const onDrop = (acceptedFiles: File[]) => {
    const imageFiles = acceptedFiles.filter((file) =>
      file.type.startsWith('image/'),
    );

    if (imageFiles.length < acceptedFiles.length) {
      alert('Endast bildfiler är tillåtna (PNG, JPG, JPEG, GIF, WEBP)');
    }

    setFiles((prevFiles) => [...prevFiles, ...imageFiles]);
  };

  const handlePreviewClick = async () => {
    if (!selectedColor || files.length === 0) {
      alert('Vänligen välj en färg och lägg till bilder.');
      return;
    }

    setIsProcessing(true);

    const formData = new FormData();
    files.forEach((file) => formData.append('images', file));
    formData.append('articleNumber', productNumber);
    formData.append('colorCode', selectedColor);
    formData.append('attribute1Number', attribute1Number);
    formData.append('transparentIndices', JSON.stringify(transparentIndices));

    await handlePreview({
      articleNumber: productNumber,
      colorCode: selectedColor,
      attribute1Number: attribute1Number,
      images: files,
      transparentIndices: transparentIndices,
    });

    setIsProcessing(false);
    setFiles([]);
  };

  const handleSubmit = async () => {
    setIsSaving(true);
    try {
      await onSubmit({
        articleNumber: productNumber,
        colorCode: selectedColor,
        attribute1Number: attribute1Number,
        images: files,
        transparentIndices: transparentIndices,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleImageClick = (url: string) => {
    setSelectedImage(url);
  };

  const handleCloseImageViewer = () => {
    setSelectedImage(null);
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <div
          className="modal-content"
          style={{
            padding: '1.5rem',
            backgroundColor: 'rgba(30, 41, 59, 0.8)',
            margin: '50px auto',
            maxWidth: '600px',
            maxHeight: '80vh',
            overflowY: 'auto',
            position: 'relative',
            borderRadius: '1.25rem',
            backdropFilter: 'blur(20px)',
            border: '1px solid rgba(248, 250, 252, 0.08)',
            boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
            color: '#F8FAFC',
          }}
        >
          {isSaving && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(30, 41, 59, 0.8)',
                backdropFilter: 'blur(20px)',
                zIndex: 1,
                borderRadius: '1.25rem',
              }}
            >
              <CircularProgress sx={{ color: '#8B5CF6' }} />
            </div>
          )}

          <Typography
            variant="h4"
            gutterBottom
            sx={{
              background: 'linear-gradient(135deg, #8B5CF6, #10B981)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              fontWeight: 700,
              letterSpacing: '-0.02em',
              marginBottom: '1.5rem',
            }}
          >
            Ladda upp bilder
          </Typography>

          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{
              color: 'rgba(248, 250, 252, 0.75)',
              marginBottom: '1.5rem',
            }}
          >
            Artikelnummer: {productNumber}
          </Typography>

          <Autocomplete
            options={availableColors}
            value={selectedColor}
            onChange={(event, newValue) => {
              setSelectedColor(newValue || '');
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Färg"
                variant="outlined"
                fullWidth
                margin="normal"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    background: 'rgba(248, 250, 252, 0.03)',
                    backdropFilter: 'blur(10px)',
                    borderRadius: '0.75rem',
                    transition: 'all 0.2s ease-in-out',
                    '&:hover': {
                      background: 'rgba(248, 250, 252, 0.05)',
                    },
                    '&.Mui-focused': {
                      background: 'rgba(248, 250, 252, 0.05)',
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#8B5CF6',
                        borderWidth: '2px',
                      },
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(248, 250, 252, 0.08)',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: 'rgba(248, 250, 252, 0.7)',
                  },
                  '& .MuiOutlinedInput-input': {
                    color: '#F8FAFC',
                  },
                  '& .MuiAutocomplete-endAdornment': {
                    '& .MuiSvgIcon-root': {
                      color: 'rgba(248, 250, 252, 0.7)',
                    },
                  },
                }}
              />
            )}
            sx={{ mb: 3 }}
          />

          <DropzoneArea
            onDrop={onDrop}
            files={files}
            setFiles={setFiles}
            transparentIndices={transparentIndices}
            setTransparentIndices={setTransparentIndices}
          />

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '1.5rem',
            }}
          >
            <Button
              onClick={handlePreviewClick}
              variant="outlined"
              disabled={isProcessing}
              sx={{
                borderColor: '#8B5CF6',
                color: '#F8FAFC',
                borderRadius: '0.75rem',
                padding: '0.625rem 1.25rem',
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  borderColor: '#7C3AED',
                  background: 'rgba(139, 92, 246, 0.1)',
                },
                '&:disabled': {
                  borderColor: 'rgba(248, 250, 252, 0.2)',
                  color: 'rgba(248, 250, 252, 0.3)',
                },
              }}
            >
              {isProcessing ? 'Bearbetar...' : 'Förhandsgranska'}
            </Button>
          </div>

          {uploadedImages.length > 0 && (
            <div style={{ marginTop: '2rem' }}>
              <Typography
                variant="h6"
                sx={{
                  color: '#F8FAFC',
                  marginBottom: '1rem',
                  fontWeight: 600,
                }}
              >
                Bearbetade bilder:
              </Typography>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
                  gap: '1rem',
                  maxWidth: '100%',
                  overflow: 'hidden',
                }}
              >
                {uploadedImages.map((url, index) => {
                  const fileName = url.split('/').pop() || url;
                  const fullUrl = `https://mirakel-api.enghssport.com/processed/${fileName}`;
                  return (
                    <Box
                      key={index}
                      sx={{
                        position: 'relative',
                        aspectRatio: '3/4',
                        borderRadius: '0.75rem',
                        overflow: 'hidden',
                        cursor: 'pointer',
                        transition: 'transform 0.2s ease-in-out',
                        '&:hover': {
                          transform: 'scale(1.05)',
                        },
                      }}
                    >
                      <img
                        src={fullUrl}
                        alt={`Bild ${index + 1}`}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                        onClick={() => handleImageClick(fullUrl)}
                      />
                    </Box>
                  );
                })}
              </div>
            </div>
          )}

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '2rem',
              gap: '1rem',
            }}
          >
            <Button
              onClick={onClose}
              sx={{
                color: 'rgba(248, 250, 252, 0.7)',
                padding: '0.625rem 1.25rem',
                borderRadius: '0.75rem',
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  backgroundColor: 'rgba(248, 250, 252, 0.05)',
                  color: '#F8FAFC',
                },
              }}
            >
              Avbryt
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              disabled={uploadedImages.length === 0}
              sx={{
                background: 'linear-gradient(135deg, #8B5CF6, #10B981)',
                borderRadius: '0.75rem',
                padding: '0.625rem 1.25rem',
                fontWeight: 500,
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  background: 'linear-gradient(135deg, #7C3AED, #059669)',
                  transform: 'translateY(-1px)',
                  boxShadow: '0 10px 20px -10px rgba(139, 92, 246, 0.5)',
                },
                '&:active': {
                  transform: 'translateY(0)',
                },
                '&:disabled': {
                  background: 'rgba(248, 250, 252, 0.1)',
                  color: 'rgba(248, 250, 252, 0.3)',
                },
              }}
            >
              Spara
            </Button>
          </div>
        </div>
      </Modal>

      {/* Modal för förstorad bild */}
      <Modal
        open={!!selectedImage}
        onClose={handleCloseImageViewer}
        aria-labelledby="image-preview-modal"
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'rgba(30, 41, 59, 0.95)',
            padding: '2rem',
            borderRadius: '1.25rem',
            maxWidth: '90vw',
            maxHeight: '90vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backdropFilter: 'blur(20px)',
            border: '1px solid rgba(248, 250, 252, 0.08)',
            boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
          }}
        >
          <img
            src={selectedImage || ''}
            alt="Förstorad bild"
            style={{
              maxWidth: '100%',
              maxHeight: 'calc(90vh - 120px)',
              objectFit: 'contain',
              borderRadius: '0.75rem',
            }}
          />
          <Button
            onClick={handleCloseImageViewer}
            sx={{
              marginTop: '1.5rem',
              color: '#F8FAFC',
              borderColor: 'rgba(248, 250, 252, 0.2)',
              borderRadius: '0.75rem',
              padding: '0.625rem 1.25rem',
              transition: 'all 0.2s ease-in-out',
              '&:hover': {
                borderColor: '#8B5CF6',
                background: 'rgba(139, 92, 246, 0.1)',
              },
            }}
            variant="outlined"
          >
            Stäng
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default UploadMediaModal;
