import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { Navigate } from 'react-router-dom';

interface Props {
  requiredPermissions: string[];
  children: JSX.Element;
}

const PermissionWrapper: React.FC<Props> = ({
  requiredPermissions,
  children,
}) => {
  const { permissions } = useContext(AuthContext);

  const hasPermission = requiredPermissions.every((permission) =>
    permissions.includes(permission),
  );

  if (!hasPermission) {
    return <Navigate to="/unauthorized" replace />;
  }

  return children;
};

export default PermissionWrapper;
